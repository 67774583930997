import { OrderflowDetails } from '@/store/orderFlow';
import { getConnectWindow } from '@smallcase/utils';
import {
  sdkMessageType,
  type SdkMessageTypeValues,
  interactionErrorMap,
  type InteractionErrorMap,
  type Intents,
  type TransactionOrderTypes,
  type SdkStatusTypeValues,
  transactionOrderTypes,
  sdkStatusType,
  intents,
  OnboardingInteractionErrors,
  MfTxnInteractionErrors,
} from '../constants';

import getUrlParams, { OpenMode } from './urlParams';
import { orderType, transactionType } from '@/constants/orderConstants';

/**
 * Gives orderType for our store, based on the `transactionOrderType` from gw
 */
export function getOrderTypeFromTransactionOrdersType(
  type: TransactionOrderTypes,
): OrderflowDetails['orderType'] {
  switch (type) {
    case transactionOrderTypes.BUY:
      return orderType.INVESTMORE;
    case transactionOrderTypes.SELL:
      return orderType.EXIT;
    default:
      return orderType.NONE;
  }
}

/**
 * Gives transaction type for our store, based on the intent from gw
 */
export function getTransactionTypeFromIntent(
  intent: Intents,
): OrderflowDetails['transactionType'] {
  switch (intent) {
    case intents.ONBOARDING:
      return transactionType.ONBOARDING;
    case intents.MANDATE:
      return transactionType.MANDATE;
    case intents.TRANSACTION:
    case intents.SIP_SETUP:
      return transactionType.SINGLE_MUTUAL_FUND;
    default:
      throw new Error('intent not handled');
  }
}

/* ------------------ !SECTION SDK Utils ------------------- */

type InteractionStatusPayload = {
  /** Metadata regarding `status` of the transaction */
  status: SdkStatusTypeValues;
} & (
  | {
      /** error message for the gw sdk */
      error?: string;
      /** Code related to the error. */
      code?: number;
    }
  | InteractionErrorMap
);
type SdkPostMessage = {
  /** Type of sdk message */
  type: SdkMessageTypeValues;
  payload?: InteractionStatusPayload;
};

/** GW is not providing us the openMode so we figure that out from the window object present with us */
const getOpenMode = (windowObject = window): OpenMode => {
  if (windowObject.parent !== window) {
    return OpenMode.iframe;
  }
  return OpenMode.newtab;
};

/**
 * WEB
 * Send post message to connect window
 */
const sendPostMessage = (message: SdkPostMessage) => {
  console.log(
    `gw-fe-mf - Trying to send postMessage: `,
    JSON.stringify(message, null, 4),
  );

  const openMode = getOpenMode(window);

  // Send message only when a proper parent window is available
  const openerWindowObject = getConnectWindow(openMode, window);
  /** Flattening the object as per same contract from Bp */
  const postMessageObject = {
    type: message.type,
    ...message.payload,
  };

  if (openerWindowObject !== null) {
    openerWindowObject.postMessage(postMessageObject, '*');
  } else {
    console.log('But cancelled as window parent is not available :(');
  }
};

/**
 * NATIVE APP
 * Open the App with a generated deeplink with data from searchParams
 */
const openDeeplinkToNativeSdk = (
  { payload }: SdkPostMessage,
  options: { gateway: string },
) => {
  const paramString = new URLSearchParams(
    // Filtering out the non-empty searchParams
    // @ts-ignore, boolean and number are easily coerced to string
    Object.entries(payload).filter((pair) => pair[1] !== undefined),
  );
  /** Protocol that opens the app */
  const nativeUrl = `scgateway://${options.gateway}?${paramString}`;
  console.log(`Navigating to ${nativeUrl}`);
  window.location.replace(nativeUrl);
};

/**
 * Posts back the message to gw sdk, either native or web, based on the `clientType` from the searchParams.
 * https://mf-stag.smallcase.com/transaction/TRX_99a7023b3ee148d7946dcb16317ad93d?gateway=gatewaydemo-stag&clientType=web
 * @param message - postMessage for GW SDK
 */
export const dispatchToSdk = (message: SdkPostMessage) => {
  /** searchParams sent by gw */
  const { gateway, clientType } = getUrlParams();
  if (
    clientType.toLowerCase() === 'android' ||
    clientType.toLowerCase() === 'ios'
  )
    openDeeplinkToNativeSdk(message, { gateway });
  else sendPostMessage(message);
};

/**
 * Marks trx complete with passing INTERNAL_ERROR, and exit the flow.
 */
export const exitWithInternalError = () => {
  dispatchToSdk({
    type: sdkMessageType.TRANSACTION_COMPLETE,
    payload: {
      status: sdkStatusType.ERRORED,
      ...interactionErrorMap.INTERNAL_ERROR,
    },
  });
};

/** When user closes the modal and the transaction gets terminated. */
export const exitWithUserCancelled = () => {
  dispatchToSdk({
    type: sdkMessageType.USER_CANCELLED,
    payload: {
      status: sdkStatusType.COMPLETED,
      ...interactionErrorMap.USER_CANCELLED,
    },
  });
};

/**
 * dispatch a message to sdk that the flow is complete
 */
export const markTransactionComplete = () => {
  dispatchToSdk({
    type: sdkMessageType.TRANSACTION_COMPLETE,
    payload: {
      status: sdkStatusType.COMPLETED,
    },
  });
};

export const markTransactionAsPending = () => {
  dispatchToSdk({
    type: sdkMessageType.TRANSACTION_COMPLETE,
    payload: {
      status: sdkStatusType.PROCESSING,
    },
  });
};

export const markTransactionAsExpired = () => {
  dispatchToSdk({
    type: sdkMessageType.TRANSACTION_EXPIRED,
    payload: {
      status: sdkStatusType.ERRORED,
      ...interactionErrorMap.TRANSACTION_EXPIRED,
    },
  });
};

export const exitWithOnboardingErrors = (
  interactionErrorType: OnboardingInteractionErrors,
) => {
  dispatchToSdk({
    type: sdkMessageType.TRANSACTION_COMPLETE,
    payload: {
      status: sdkStatusType.ERRORED,
      ...interactionErrorMap[interactionErrorType],
    },
  });
};

/** Onboarding is successfully completed and user will return to the consumer's platform app/.com/3rd party */
export const exitWithOnboardingComplete = () => {
  dispatchToSdk({
    type: sdkMessageType.TRANSACTION_COMPLETE,
    payload: {
      status: sdkStatusType.COMPLETED,
    },
  });
};

/** Onboarding is chained with orderflow-transaction and has to be continued further */
export const onboardingCompleteAndInitOrderflowTxn = () => {
  dispatchToSdk({
    type: sdkMessageType.PROCESSING,
    payload: {
      status: sdkStatusType.PROCESSING,
    },
  });
};

export const exitWithMfTxnErrors = (
  interactionErrorType: MfTxnInteractionErrors,
) => {
  dispatchToSdk({
    type: sdkMessageType.TRANSACTION_COMPLETE,
    payload: {
      status: sdkStatusType.ERRORED,
      ...interactionErrorMap[interactionErrorType],
    },
  });
};

export const markTradingNotAllowed = () => {
  dispatchToSdk({
    type: sdkMessageType.TRANSACTION_COMPLETE,
    payload: {
      status: sdkStatusType.ERRORED,
      ...interactionErrorMap.TRADING_NOT_ALLOWED,
    },
  });
};

/** Will remove these 👇 if web origin check not required */
// /**
//  * Validated the open for Native and Web. Closes if opener is invalid.
//  */
// export const findOpener = async () => {
//   const { clientType } = getUrlParams();
//   if (clientType === 'android' || clientType === 'ios') {
//     // if iframe on native
//     if (window.parent !== window) exitWithInternalError();
//     return undefined;
//   }
//   // if not iframe on web or not valid clientType
//   if (window.parent === window || clientType !== 'web') exitWithInternalError();
//   const appOrigin = await askWebAppOrigin();
//   return {
//     type: 'origin' as const,
//     value: appOrigin,
//   } as const;
// };

// /**
//  * checks the origin of page that opened LAS client
//  * @returns string if opener is found in a sec or null
//  */
// const askWebAppOrigin = async (): Promise<string | null> => {
//   sendPostMessage({ type: sdkMessageType.ASK_APP_ORIGIN });
//   const timeoutPromise: Promise<null> = new Promise((resolve) =>
//     setTimeout(() => resolve(null), 1000),
//   );
//   const messagePromise: Promise<string> = new Promise((resolve) => {
//     window.addEventListener('message', (event) => {
//       if (event.data.type === sdkMessageType.GIVE_APP_ORIGIN)
//         resolve(event.origin);
//     });
//   });
//   return Promise.race([messagePromise, timeoutPromise]);
// };
