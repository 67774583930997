import { useEffect } from 'react';

/**
 * hook to handle the esc click when esc is triggered by user
 * @param handler - function which needs to be triggered when user clicks on esc key
 */
export default function useOnClickEsc(handler: (e: KeyboardEvent) => void) {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key !== 'Escape') {
        return;
      }

      handler(event);
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };

    /**
     * if you are passing a handler which will change on ever re-render do consider wrapping
     * it inside the useCallback to prevent un-necessary triggers of this effect
     */
  }, [handler]);
}
