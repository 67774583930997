import { ObjectValues } from '@smallcase/types';

export const orderType = {
  EXIT: 'EXIT',
  INVESTMORE: 'INVESTMORE',
  SIP: 'SIP',
  MANDATE: 'MANDATE',
  NONE: 'NONE',
} as const;

export type OrderType = ObjectValues<typeof orderType>;

/** Differentiator in Buy and InvestMore orderflow */
export const OrderSubType = {
  BUY: 'BUY',
  INVESTMORE: 'INVESTMORE',
  NONE: 'NONE',
} as const;
export type OrderSubType = ObjectValues<typeof OrderSubType>;

export const orderLabel = {
  SELL: 'SELL',
  BUY: 'BUY',
  SELL_ALL: 'SELLALL',
} as const;

export type OrderLabel = ObjectValues<typeof orderLabel>;

export const imPaymentPostMessageId = 'mf-payment';

export const batchStatus = {
  /**
   * The order is created by the platform
   */
  ACKED: 'ACKED',
  /**
   * waiting for some process to complete. for e.g. -> Mandate debit
   */
  WAITING: 'WAITING',
  /**
   * Hanging Status, Order was placed on MFU but callback is pending or api broke on their side or open
   * This is there to prevent duplicate orders
   */
  PLACING: 'PLACING',
  /**
   * Order is successfully placed on MFU side. Terminal Status for transactions
   */
  PLACED: 'PLACED',
  /**
   * Hanging status, proper use case is not described
   */
  ERROR: 'ERROR',
  /**
   * The order is successfully completed on RTA's and units are allocated.
   * Happens after 3 days of order placement
   */
  COMPLETED: 'COMPLETED',
  /**
   * Order is rejected by MFU
   */
  UNPLACED: 'UNPLACED',
  /**
   * The order was rejected by RTA's because of some reason i.e. Lack of funds or some other reason
   * Happens after 3 days of order placement
   */
  UNFILLED: 'UNFILLED',
  /**
   * In case of multiple orders, Few orders were successfully placed on MFU and few were rejected.
   */
  PARTIALLYPLACED: 'PARTIALLYPLACED',
  /**
   * In case of multiple orders, Few orders were successfully executed by RTA's and few were rejected.
   */
  PARTIALLYFILLED: 'PARTIALLYFILLED',
  /**
   * Payment Failed for the buy order
   */
  PAYMENTFAILED: 'PAYMENTFAILED',
} as const;

export type BatchStatus = ObjectValues<typeof batchStatus>;

export const otpStatus = {
  VERIFIED: 'VERIFIED',
  PENDING: 'PENDING',
} as const;

export type OtpStatus = ObjectValues<typeof otpStatus>;

/**
 * Order status of individual orders placed as part of batch
 */
export const orderStatus = {
  /**
   * Order is created
   */
  ACKED: 'ACKED',
  /**
   * order is placed on MFU
   */
  PLACED: 'PLACED',
  /**
   * order is successfully executed by RTA's and units are allocated
   */
  COMPLETE: 'COMPLETE',
  /**
   * Order is rejected by MFU / RTA's
   */
  REJECTED: 'REJECTED',
  /**
   * Some error occurred with placement
   */
  ERROR: 'ERROR',
} as const;

export type OrderStatus = ObjectValues<typeof orderStatus>;

/** These are purely FE based copies/cases of transactions */
export const transactionType = {
  SINGLE_MUTUAL_FUND: 'SINGLE_MUTUAL_FUND',
  MANDATE: 'MANDATE',
  SMALLCASE_OF_MUTUAL_FUNDS: 'SMALLCASE_OF_MUTUAL_FUNDS',
  ONBOARDING: 'ONBOARDING',
  NONE: 'NONE',
} as const;

export type TransactionType = ObjectValues<typeof transactionType>;

export const paymentStatus = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
} as const;

export type PaymentStatus = ObjectValues<typeof paymentStatus>;

export const paymentModes = {
  UPI: 'UPI',
  DEBITCARD: 'DEBITCARD',
  NETBANKING: 'NETBANKING',
  MANDATE: 'MANDATE',
} as const;

export type PaymentModes = ObjectValues<typeof paymentModes>;

export const sellRestrictionCodes = {
  DEMAT_ONLY_INVESTMENT: 'DEMAT_ONLY_INVESTMENT',
  NOT_INVESTED: 'NOT_INVESTED',
  MFU_REDEMPTION_NOT_ALLOWED: 'MFU_REDEMPTION_NOT_ALLOWED',
  FUNDS_LOCKED: 'FUNDS_LOCKED',
  DATA_NOT_AVAILABLE: 'DATA_NOT_AVAILABLE',
} as const;

export type SellRestrictionCodes = ObjectValues<typeof sellRestrictionCodes>;

export const buyRestrictionCodes = {
  MFU_PURCHASE_NOT_ALLOWED: 'MFU_PURCHASE_NOT_ALLOWED',
  REGULAR_FUND: 'REGULAR_FUND',
  CLOSED_FUNDS: 'CLOSED_FUNDS',
  DATA_NOT_AVAILABLE: 'DATA_NOT_AVAILABLE',
} as const;

export type BuyRestrictionCodes = ObjectValues<typeof buyRestrictionCodes>;

// UPI limit is 1 lakh, payments more than 1L are not allowed via UPI
export const MAX_UPI_LIMIT = 100_000;
export const PaymentModeDisabledInfoText: {
  [Mode in PaymentModes]?: string;
} = {
  [paymentModes.UPI]:
    'You cannot use UPI for this investment since the investment amount is greater than daily UPI limit',
} as const;

export type PaymentModeDisabledInfoText = ObjectValues<
  typeof PaymentModeDisabledInfoText
>;

/**
 * Validation functions for disabling the particular payment modes
 * Returns true if payment mode shall be disabled. If it returns false - payment mode will be available
 */
export const PaymentModeDisabledChecker: {
  [Mode in PaymentModes]: (param: unknown) => boolean;
} = {
  [paymentModes.DEBITCARD]: () => false,
  [paymentModes.MANDATE]: () => false,
  [paymentModes.NETBANKING]: () => false,
  [paymentModes.UPI]: (amount) => {
    if (Number(amount) > MAX_UPI_LIMIT) {
      return true;
    }
    return false;
  },
} as const;
