import { Mfuser } from '@/services/sessionManagement/sessionManagementServices';
import { ObjectValues } from '@smallcase/types';
import { OrderflowTrackingEvents } from '../SingleMutualFunds/analytics/eventNames';
import { ClientUrlParams } from '@/gatewayApp/utils/urlParams';
import { OnboardingEvents } from '@/Onboarding/analytics/eventNames';
import { SharedTrackingEvents } from './sharedEventsAndProperties';

export const AnalyticsLibraries = {
  MIX_PANEL: 'MixPanel',
  GOOGLE_TAG_MANAGER: 'GoogleTagManager',
  CLEVER_TAP: 'CleverTap',
} as const;
export type AnalyticsLibraries = ObjectValues<typeof AnalyticsLibraries>;

export const AnalyticsEventNames = {
  ...OnboardingEvents,
  ...OrderflowTrackingEvents,
  ...SharedTrackingEvents,
};
export type AnalyticsEventNames = ObjectValues<typeof AnalyticsEventNames>;

// TODO will be ideally coming from gwUrlParams
export const AssetType = {
  mf: 'mutual_funds',
  smallcase: 'smallcase',
} as const;
export type AssetType = ObjectValues<typeof AssetType>;

export const ClientType = {
  android: 'android',
  ios: 'ios',
  web: 'web',
} as const;
export type ClientType = 'android' | 'ios' | 'web';

export const CommonTrackingProperties = {
  accessedFrom: 'accessedFrom',
  sectionTag: 'sectionTag',
  deviceType: 'deviceType',
  gatewayName: 'gatewayName',
  gatewayType: 'gatewayType',
  clientType: 'clientType',
  assetType: 'assetType',
} as const;
export type CommonTrackingProperties = ObjectValues<
  typeof CommonTrackingProperties
>;

export interface CommonTrackingPropertiesType {
  [CommonTrackingProperties.accessedFrom]: string;
  [CommonTrackingProperties.sectionTag]: string;
  [CommonTrackingProperties.deviceType]: ClientUrlParams['clientType']; // This property name is used by analytics team
  [CommonTrackingProperties.gatewayType]: GatewayType;
  [CommonTrackingProperties.gatewayName]: ClientUrlParams['gateway'];
  [CommonTrackingProperties.clientType]: ClientUrlParams['clientType'];
  [CommonTrackingProperties.assetType]: ClientUrlParams['assetType'];
}

export type PossibleAnalyticsPropertyType =
  | string
  | number
  | boolean
  | undefined
  | null;
export interface AnalyticsExtraProps {
  // Any extra properties that has to be send while tracking an event/ optional keys
  [key: string]: PossibleAnalyticsPropertyType;
}

export type UserObjectType = Mfuser['data'];

export const GatewayType = {
  mweb: 'smallcase-app',
  web: 'smallcase-website',
  // new generic enum will be added after gw mf-txn externalization
} as const;
export type GatewayType = ObjectValues<typeof GatewayType>;

/**
 * substitute for properties that are undefined
 */
export const NOT_AVAILABLE = 'N/A';
/** local store key */
export const UTM_STORE_KEY = 'sessionUTMs';
/** time limit for UTM expiry */
export const UTM_LIFETIME = 2.592e9;
