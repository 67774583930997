import OrderModalInterstitial from '@/components/OrderModalInterstitial/OrderModalInterstitial';
import React, { Suspense } from 'react';

const Onboarding = React.lazy(() => import('./Onboarding'));

export default function OnboardingRenderer() {
  return (
    <Suspense
      fallback={
        <OrderModalInterstitial
          description="Please wait while we bring awesomeness"
          heading="Preparing your order..."
          overlay={false}
          showClose={false}
          showPartnerInfo
        />
      }
    >
      <Onboarding />
    </Suspense>
  );
}
