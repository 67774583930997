import {
  transactionType,
  TransactionType,
  orderType,
  OrderType,
  OrderSubType,
} from '@/constants/orderConstants';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type SupportedTransactionType = Exclude<
  TransactionType,
  typeof transactionType.SMALLCASE_OF_MUTUAL_FUNDS
>;

type SupportedOrderType = Exclude<OrderType, typeof orderType.SIP>;

export type OrderflowDetails = {
  transactionType: SupportedTransactionType;
  orderType: SupportedOrderType;
  orderSubType?: OrderSubType;
  mfId: string;
  orderStack?: OrderStack;
};

type OrderStack = {
  transactionType: SupportedTransactionType;
  orderSubType?: OrderSubType;
  orderType: SupportedOrderType;
  mfId: string;
}[];

type State = {
  /**
   * The type of transaction which needs to be rendered
   */
  transactionType: SupportedTransactionType;
  /**
   * order type from the transaction which needs to be shown
   */
  orderType: SupportedOrderType;
  /**
   * order sub type is a discriminator between Buy and InvestMore orderflow
   */
  orderSubType?: OrderSubType;
  /**
   * unique identifier of the mutual fund
   */
  mfId: string;
  /**
   * stack of order if some order needs to be opened after the current order
   */
  orderStack: OrderStack;
  /**
   * function to set the order flow details
   * @param params - params required by the function
   */
  setOrderFlowDetails(params: OrderflowDetails): void;
  /**
   * function to set the orderSubType
   * @param orderSubType - order sub type
   */
  setOrderSubType(orderSubType: OrderSubType): void;
  /**
   * function to clear out the order flow details
   */
  clearOrderFlowDetails(): void;
  /**
   * function to push an order flow to the stack
   * @param params - params required to push the order flow
   */
  pushOrderToStack(params: { orderStack: OrderStack }): void;
  /**
   * function to push the order flow to stack and change the current order
   * @param params - params required to change the order flow
   */
  pushOrderToStackAndChangeCurrentOrder(params: {
    transactionType: SupportedTransactionType;
    orderType: SupportedOrderType;
    orderSubType?: OrderSubType;
    mfId: string;
    orderStack: OrderStack;
  }): void;
  /**
   * function to clear out the stack
   */
  clearOrderStack(): void;
  /**
   * function to remove the first order from the stack and proceed with that order flow
   */
  popOrderFromStackAndContinueWithOrderFlow(): void;
};

/**
 * zustand store to manage the transactions on the platform
 */
export const useOrderFlow = create<State>()(
  devtools(
    (set) => ({
      transactionType: transactionType.NONE,
      orderType: orderType.NONE,
      orderSubType: OrderSubType.NONE,
      mfId: '',
      orderStack: [],
      setOrderFlowDetails(params) {
        set({ ...params });
      },
      setOrderSubType(orderSubType: OrderSubType) {
        set({ orderSubType });
      },
      clearOrderFlowDetails() {
        set(() => ({
          transactionType: transactionType.NONE,
          orderType: orderType.NONE,
          orderSubType: OrderSubType.NONE,
          mfId: '',
          orderStack: [],
        }));
      },
      pushOrderToStack(params) {
        set((state) => ({
          orderStack: [...state.orderStack, ...params.orderStack],
        }));
      },
      pushOrderToStackAndChangeCurrentOrder(params) {
        set((state) => ({
          orderStack: [...state.orderStack, ...params.orderStack],
          mfId: params.mfId,
          orderType: params.orderType,
          orderSubType: params.orderSubType
            ? params.orderSubType
            : OrderSubType.NONE,
          transactionType: params.transactionType,
        }));
      },
      clearOrderStack() {
        set({ orderStack: [] });
      },
      popOrderFromStackAndContinueWithOrderFlow() {
        set((state) => {
          const newStack = [...state.orderStack];
          const topOrder = newStack.pop();

          if (!topOrder) return {};

          return {
            orderStack: newStack,
            mfId: topOrder.mfId,
            orderType: topOrder.orderType,
            transactionType: topOrder.transactionType,
          };
        });
      },
    }),
    {
      name: 'ORDERFLOW_STORE',
      anonymousActionType: 'ORDERFLOW_STORE',
    },
  ),
);
