import { AssetType, ClientType } from '@/analytics/constants';
import { ObjectValues } from '@smallcase/types';

export const OpenMode = { newtab: 'newtab', iframe: 'iframe' } as const;
export type OpenMode = ObjectValues<typeof OpenMode>;

export type ClientUrlParams = {
  /** identifier for the gateway partner */
  gateway: string;
  /** type of device that the client is opened in */
  clientType: ClientType;
  /** Where is the URL accessed from */
  accessedFrom: string;
  /** From which section the platform is triggered */
  sectionTag: string;
  /** asset type */
  assetType: AssetType;
  /** If opened in iFrame/newTab. */
  openMode: OpenMode;
};

/**
 * returns an object containing all the parsed url parameters and their values
 **/
const getUrlParams = () => {
  let urlParams: Record<string, string | string[] | undefined> = {};
  if (globalThis.window) {
    urlParams = Object.fromEntries(
      new URLSearchParams(globalThis.window.location.search),
    );
  }
  if (!urlParams.clientType) {
    urlParams.clientType = 'web';
  }
  return urlParams as ClientUrlParams;
};

export default getUrlParams;
