/** Not moving this file to /constants.ts because of a cyclic dep in analytics/constants */
import { ObjectValues } from '@smallcase/types';

export const OnboardingEvents = {
  // When user starts the onboarding flow here.
  VIEWED_ACCOUNT_SETUP_POPUP: 'MF - Viewed Account Setup Popup',
  // When user clicks on confirm via otp and sees the otp verification popup.
  VIEWED_MFC_VERIFICATION_OTP_POPUP: 'MF - Viewed MFC Verification Otp Popup',
  SUBMITTED_MFC_VERIFICATION_OTP: 'MF - Submitted MFC Verification Otp',
  // When the kyc verification page is shown to the user
  VIEWED_KYC_VERIFICATION_PAGE: 'MF - Viewed Kyc Verification Page',
  // When the kyc verification failed and error message is displayed to the user
  VIEWED_KYC_VERIFICATION_FAILED: 'MF - Viewed Kyc Verification Failed',
  // When user view the confirm personal details popup. Details like email is pre filled for track user.
  VIEWED_CONFIRMED_PERSONAL_DETAILS: 'MF - Viewed Confirmed Personal Details',
  // When user inputs email, occupation and income bracket and clicks on confirm personal details.
  CONFIRMED_PERSONAL_DETAILS: 'MF - Confirmed Personal Details',
  // When add nominee popup is opened after user confirms personal details
  VIEWED_ADD_NOMINEE_POPUP: 'MF - Viewed Add Nominee Popup ',
  // When user clicks on confirm via otp and sees the otp verification popup.
  VIEWED_NOMINEE_VERIFICATION_OTP_POPUP:
    'MF - Viewed Nominee Verification Otp Popup',
  SUBMITTED_NOMINEE_VERIFICATION_OTP: 'MF - Submitted Nominee Verification Otp',
  // When the user views the signature upload screen
  VIEWED_SIGNATURE_CHOOSER: 'MF- Viewed Signature Upload page',
  // When the user clicks on Tap here to draw signature
  CLICKED_DRAW_SIGNATURE: 'MF- Clicked Draw Signature',
  // When the user clicks on Confirm after drawing their signature
  CLICKED_CONFIRM_DRAWN_SIGNATURE: 'MF- Clicked Confirm Signature',
  // When the user clears the signature after drawing
  CLICKED_CLEAR_DRAWN_SIGNATURE: 'MF- Clicked Clear Signature',
  // When the user clicks on Draw again to redraw the signature- Resets the signature
  CLICKED_DRAW_AGAIN: 'MF- Clicked Draw Again',
  // When the user clicks on replace image to replace the uploaded image
  CLICKED_REPLACED_IMAGE: 'MF- Clicked Replace Image',
  // When the user clicks on take a photo
  CLICKED_UPLOAD_SIGNATURE: 'MF - Clicked Upload Signature',
  // When the user clicks on continue to submit the signature
  SUBMITTED_SIGNATURE: 'MF - Submitted Signature',
  // When user clicks on do not add nominee
  CLICKED_DO_NOT_ADD_NOMINEE: 'MF - Clicked Do Not Add Nominee',
  // When bank verification window pops up after user confirms nominee details
  VIEWED_VERIFY_BANK_ACCOUNT_POPUP: 'MF - Viewed Verify Bank Account Popup',
  // When user clicks on verify bank account.
  CLICKED_VERIFY_BANK_ACCOUNT: 'MF - Clicked Verify Bank Account',
  // When user views the bank verification successful popup.
  BANK_VERIFICATION_SUCCESSFUL: 'MF - Bank Verification Successful',
  // When user clicks on add new bank a/c.
  CLICKED_ADD_NEW_BANK_ACCOUNT: 'MF - Clicked Add New Bank Account',
  // When user clicks on search button for bank branch
  CLICKED_SEARCH_BANK_BRANCH: 'MF - Clicked Search Bank Branch',
  // When user clicks on done button after selecting the bank branch
  CONFIRMED_BANK_BRANCH: 'MF - Confirmed Bank Branch',
  // When user clicks on change bank account
  CLICKED_CHANGE_BANK_ACCOUNT: 'MF - Clicked Change Bank Account',
  // When user views the final onboarding status (could be either onboarding in process or onboarding successful)
  VIEWED_ONBOARDING_STATUS: 'MF - Viewed Onboarding Status',
  // When user views failed bank verifications. User can face 3 different types of error which will be stored in the event property - bankVerificationError
  VIEWED_BANK_VERIFICATION_FAILURE: 'MF - Viewed Bank Verification Failure ',
  // When user clicks on retry bank verification after facing an error
  RETRIED_BANK_VERIFICATION: 'MF - Retried Bank Verification',
  // When user clicks on edit bank account after facing bank verification failed error
  CLICKED_EDIT_BANK_ACCOUNT: 'MF - Clicked Edit Bank Account',
  // KYC Fetching may fail in two cases. These cases are stored as event property - kycErrorType.
  // kycErrorType:
  // 1) API Level Failure
  // 2) Details Not Matched
  // 3) KYC Not Verified
  VIEWED_FAILED_KYC_FETCH: 'MF - Viewed Failed KYC Fetch',
  // When KYC fetching fails, and user retries
  CLICKED_RETRY_KYC_FETCH: 'MF - Clicked Retry KYC Fetch',
  // When user clicks on start again after seeing kyc fetch error
  CLICKED_RESTART_ONBOARDING: 'MF - Clicked Restart Onboarding',
  // When user clicks on smallcase support mail id
  CLICKED_SUPPORT_MAIL_LINK: 'Clicked Support Mail Link',
  // when user kyc status is on hold in mf
  VIEWED_KYC_VERIFICATION_ERROR: 'MF - Viewed KYC Verification Error',
  // When user clicks on update kyc cta in on hold screen
  CLICKED_UPDATE_KYC: 'MF - Clicked Update KYC',
  // User views the detail screen for update kyc requirements
  VIEWED_UPDATE_YOUR_KYC_SCREEN: 'MF - Viewed update your KYC',
  // User clicks on the continue to kra website cta
  CLICKED_CONTINUE_TO_KRA_WEBSITE: 'MF - Clicked Continue to KRA Website',
  // FAILED To send MFC OTP
  FAILED_TO_SEND_MFC_OTP: 'MF - Failed to send MFC otp',
  // NDML KRA upstream error
  NDML_KRA_TIMEOUT_ERROR: 'MF - NDML KRA Timeout Error',
  // KYC VENDOR TIMEOUT ERROR
  KYC_VENDOR_TIMEOUT_ERROR: 'MF - KYC Vendor Timeout Error',
  // Invalid pan format
  INVALID_PAN_FORMAT: 'MF - Invalid pan format',
} as const;

export type OnboardingEvents = ObjectValues<typeof OnboardingEvents>;
