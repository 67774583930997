import { ObjectKeys, ObjectValues } from '@smallcase/types';
import { AnalyticsEventNames } from './constants';
import {
  BuyEvents,
  EditSipEvents,
  EndSipMandateEvents,
  ErrorHandlingEvents,
  ExitEvents,
  InvestMoreEvents,
  OtpEvents,
  SipMandateEvents,
  SipNoMandateEvents,
} from '@/SingleMutualFunds/analytics/eventNames';
import { OnboardingEvents } from '@/Onboarding/analytics/eventNames';
import { SharedErrorEvents } from './sharedEventsAndProperties';

/** AnalyticsEventNames is 1st treated as a const and then as type */
export const CleverTapEventWhitelist: Partial<
  Record<ObjectKeys<typeof AnalyticsEventNames>, AnalyticsEventNames>
> = {
  // shared generic events
  GENERIC_ERROR: SharedErrorEvents.GENERIC_ERROR,

  // onboarding
  VIEWED_ACCOUNT_SETUP_POPUP: OnboardingEvents.VIEWED_ACCOUNT_SETUP_POPUP,
  VIEWED_MFC_VERIFICATION_OTP_POPUP:
    OnboardingEvents.VIEWED_MFC_VERIFICATION_OTP_POPUP,
  SUBMITTED_MFC_VERIFICATION_OTP:
    OnboardingEvents.SUBMITTED_MFC_VERIFICATION_OTP,
  VIEWED_ONBOARDING_STATUS: OnboardingEvents.VIEWED_ONBOARDING_STATUS,
  VIEWED_BANK_VERIFICATION_FAILURE:
    OnboardingEvents.VIEWED_BANK_VERIFICATION_FAILURE,
  VIEWED_FAILED_KYC_FETCH: OnboardingEvents.VIEWED_FAILED_KYC_FETCH,

  // invest more
  VIEWED_INVEST_POPUP: InvestMoreEvents.VIEWED_INVEST_POPUP,
  CONFIRMED_ORDER_TYPE: InvestMoreEvents.CONFIRMED_ORDER_TYPE,
  INITIATED_ORDER_INTENT: InvestMoreEvents.INITIATED_ORDER_INTENT,
  CONFIRMED_AMOUNT: InvestMoreEvents.CONFIRMED_AMOUNT,
  VIEWED_PAYMENTS_PAGE: InvestMoreEvents.VIEWED_PAYMENTS_PAGE,
  TRANSFERRED_AMOUNT: InvestMoreEvents.TRANSFERRED_AMOUNT,
  ORDER_STATUS_RECEIVED: InvestMoreEvents.ORDER_STATUS_RECEIVED,
  VIEWED_ORDER_COMPLETION_SCREEN:
    InvestMoreEvents.VIEWED_ORDER_COMPLETION_SCREEN,

  // Exit
  VIEWED_EXIT_POPUP: ExitEvents.VIEWED_EXIT_POPUP,
  PLACE_ORDER: ExitEvents.PLACE_ORDER,
  /** Already covered in Buy */
  // INITIATED_ORDER_INTENT: ExitEvents.INITIATED_ORDER_INTENT,
  // CONFIRMED_AMOUNT: ExitEvents.CONFIRMED_AMOUNT,
  // ORDER_STATUS_RECEIVED: ExitEvents.ORDER_STATUS_RECEIVED,
  // VIEWED_ORDER_COMPLETION_SCREEN: ExitEvents.VIEWED_ORDER_COMPLETION_SCREEN,

  // OTP
  OTP_VERIFIED: OtpEvents.OTP_VERIFIED,
  OTP_ERROR: OtpEvents.OTP_ERROR,

  // Error
  CLICKED_RETRY_PLACING_ORDER: ErrorHandlingEvents.CLICKED_RETRY_PLACING_ORDER,

  // Buy
  FIRST_PURCHASE: BuyEvents.FIRST_PURCHASE,

  // SIP
  VIEWED_START_SIP_POPUP: SipNoMandateEvents.VIEWED_START_SIP_POPUP,
  CLICKED_SETUP_MANDATE: SipNoMandateEvents.CLICKED_SETUP_MANDATE,
  SIP_STARTED: SipNoMandateEvents.SIP_STARTED,
  SELECTED_SIP_FREQUENCY: SipMandateEvents.SELECTED_SIP_FREQUENCY, // mandate event
  // edit SIP
  CLICKED_EDIT_SIP: EditSipEvents.CLICKED_EDIT_SIP,
  SIP_UPDATED: EditSipEvents.SIP_UPDATED,
  // end mandate SIP
  CLICKED_END_SIP: EndSipMandateEvents.CLICKED_END_SIP,
  SIP_ENDED: EndSipMandateEvents.SIP_ENDED,
  CLICKED_CONTINUE_SIP: EndSipMandateEvents.CLICKED_CONTINUE_SIP,
} as const;
export type CleverTapEventWhitelist = ObjectValues<
  typeof CleverTapEventWhitelist
>;
