import { useEffect } from 'react';

/**
 * hook to wrap around resize observer api of web and execute a callback when some resize is done
 * @param ref - ref of the element whose resizing needs to be observed
 * @param onResize - handler which will be executed when there is a resize
 */
export function useResizeObserver(
  ref: React.RefObject<HTMLElement>,
  onResize: () => void,
) {
  useEffect(() => {
    const resizeObserver = new ResizeObserver(onResize);

    resizeObserver.observe(ref.current!);

    return () => {
      resizeObserver.disconnect();
    };
    // You can see onResize is added as a dep directly this will cause a re-render everytime some state is updated in consumer
    // To avoid this please wrap the function in a useCallback.
  }, [onResize, ref]);
}
