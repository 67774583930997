import { useCallback, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { sessionManagementServices } from '@/services';
import { setSessionData, getSessionData } from '@/utils/authUtils';
import { authStatuses, useAuthStore } from '@/store/auth';
import useTransactionId from './useTransactionId';
import analyticsManager from '@/analytics';
import { ErrorLogging } from '@/errorReporting/sentry/logger';

/**
 * Hook encapsulating logic to fetch the user profile and user details
 * @returns handler functions to fetch the user details
 */
export function useUserDetails() {
  const fetchUser = useCallback(
    async () => sessionManagementServices.fetchMfUser(),
    [],
  );

  const fetchMfUserProfile = useCallback(
    async () => sessionManagementServices.fetchMfUserProfile(),
    [],
  );

  const fetchUserDetails = useCallback(async () => {
    let userProfile: sessionManagementServices.Mfuser['data'] &
      sessionManagementServices.UserProfileSchema = {
      analytics: {
        // when mf user does not exist (onboarding not started), this id will be SAM_ID else mfUserId
        id: '',
        // TODO CT_INTEGRATION
        clevertap: {
          id: '',
        },
      },
      onboarding: {
        required: false,
      },
    };

    const user = await fetchUser();
    userProfile = {
      ...userProfile,
      ...user,
    };

    if (!user.onboarding.required) {
      try {
        const profile = await fetchMfUserProfile();
        userProfile = {
          ...userProfile,
          ...user,
          ...profile,
        };

        return userProfile;
      } catch (err) {
        console.log(err);
      }
    }

    return userProfile;
  }, [fetchUser, fetchMfUserProfile]);

  return {
    fetchUserDetails,
    fetchMfUserProfile,
    fetchUser,
  };
}

/**
 * custom hook to create a session for mft service
 * @param transactionId - transaction id with which session will be created
 * @returns functions to create / reuse the session
 */
export function useCreateSession(transactionId: string) {
  const setAuthStatus = useAuthStore((state) => state.setAuthStatus);
  const setAuthState = useAuthStore((data) => data.setState);
  const { fetchUserDetails } = useUserDetails();

  const setSessionDataHandler = useCallback(
    (jwt: string) => {
      setSessionData({ token: jwt, transaction: transactionId });
    },
    [transactionId],
  );

  const { mutate: createSession, mutateAsync: createSessionAsync } =
    useMutation({
      mutationFn: sessionManagementServices.createSession,
      onSuccess(data) {
        setSessionDataHandler(data.jwt);
      },
    });

  const validateSessionHandler = useCallback(async () => {
    try {
      const userDetails = await fetchUserDetails();
      setAuthState({
        authStatus: authStatuses.AUTHORIZED,
        userProfile: userDetails,
      });

      /** Tracking START */
      try {
        analyticsManager.setUser(userDetails);
      } catch (e) {
        console.log(e);
      }

      try {
        ErrorLogging.setUser(userDetails.analytics.id);
      } catch {
        // NO - OP
      }
      /** Tracking END */
    } catch (err) {
      console.log(err);
      setAuthStatus(authStatuses.UNAUTHORIZED);
    }
  }, [fetchUserDetails, setAuthState, setAuthStatus]);

  const createSessionHandler = useCallback(() => {
    createSession(
      { transactionId },
      {
        onSuccess() {
          validateSessionHandler();
        },
        onError() {
          setAuthStatus(authStatuses.UNAUTHORIZED);
        },
      },
    );
  }, [createSession, setAuthStatus, transactionId, validateSessionHandler]);

  const reuseExistingSessionHandler = useCallback(() => {
    const sessionData = getSessionData();
    if (!sessionData) {
      return setAuthStatus(authStatuses.UNAUTHORIZED);
    }

    validateSessionHandler();
  }, [setAuthStatus, validateSessionHandler]);

  const updateSessionHandler = useCallback(async () => {
    const response = await createSessionAsync({ transactionId });
    setSessionDataHandler(response.jwt);
    validateSessionHandler();
  }, [
    createSessionAsync,
    setSessionDataHandler,
    transactionId,
    validateSessionHandler,
  ]);

  return {
    createSessionHandler,
    reuseExistingSessionHandler,
    updateSessionHandler,
  };
}

/**
 * Custom to create session for a user
 * @returns - status of the user session
 */
export function useSession() {
  const { transactionId, transactionIsSameAsLastUsedTransactionId } =
    useTransactionId();
  const authStatus = useAuthStore((state) => state.authStatus);

  const { createSessionHandler, reuseExistingSessionHandler } =
    useCreateSession(transactionId);

  // biome-ignore lint/correctness/useExhaustiveDependencies: We know what we are doing here...hopefully.
  useEffect(() => {
    if (transactionIsSameAsLastUsedTransactionId) {
      // If the transaction id is same as last used transaction id it means session for this is already created and no need
      // tp create new one.
      reuseExistingSessionHandler();
    } else {
      createSessionHandler();
    }

    ErrorLogging.setTransactionId(transactionId);
  }, [
    createSessionHandler,
    reuseExistingSessionHandler,
    transactionIsSameAsLastUsedTransactionId,
  ]);

  return { authStatus };
}
