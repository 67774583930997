import { apiMap } from '@/constants/apiMap';
import { ErrorLogging } from '@/errorReporting/sentry/logger';
import { httpRequest } from '@/httpService';
import { z } from 'zod';

type LoginParams = {
  /**
   * transactionId which can be used to extend the session
   */
  transactionId: string;
};

const sessionResponseSchema = z.object({
  data: z.object({
    jwt: z.string(),
  }),
});

type SessionResponse = z.infer<typeof sessionResponseSchema>;

/**
 * service to create a session for mft
 * @param params - params required to create the session
 * @returns - jwt to use for session
 */
export async function createSession(
  params: LoginParams,
): Promise<SessionResponse['data']> {
  try {
    const response = await httpRequest({
      method: 'post',
      endpoint: apiMap.LOGIN,
      service: 'transaction',
      json: {
        transactionId: params.transactionId,
      },
    });

    const result = sessionResponseSchema.parse(response);

    return result.data;
  } catch (err) {
    ErrorLogging.captureException(err, 'SINGLE_MUTUAL_FUND');
    throw err;
  }
}

const validateSessionResponseSchema = z.object({
  data: z.object({
    token: z.string(),
    userId: z.string(),
  }),
});

type ValidateSessionResponse = z.infer<typeof validateSessionResponseSchema>;

/**
 * service to validate the mft session of a user and exchange the current one with a new token to create new session
 * @returns connect token to create a new session and user id of the user
 */
export async function validateSession(): Promise<
  ValidateSessionResponse['data']
> {
  try {
    const response = await httpRequest({
      method: 'get',
      endpoint: apiMap.VALIDATE_LOGIN,
      service: 'transaction',
    });

    const result = validateSessionResponseSchema.parse(response);

    return result.data;
  } catch (err) {
    ErrorLogging.captureException(err, 'SINGLE_MUTUAL_FUND');
    throw err;
  }
}

const mfUserSchema = z.object({
  data: z.object({
    analytics: z.object({
      /**
       * This id will be mfUserId, but in case the onboarding is not yet strted -> the mfUser is not created so we receive SAM_ID here
       * For guest user (onboarding NOT_STARTED), this id will be SAM_ID which will later be linked to mfUserId
       *
       * We are keeping mfUserId as truth source because user might delink the mf account from sam_id,
       * so we want to track the events against the mfUserId instead.
       */
      id: z.string(),
      /** Clevertap id of the sam user */
      clevertap: z
        .object({
          id: z.string(),
        })
        .optional(),
    }),
    onboarding: z.object({
      required: z.boolean(),
    }),
  }),
});

export type Mfuser = z.infer<typeof mfUserSchema>;
/**
 * Service to fetch MF user data from db.
 * This is not user profile, but user's db data. User profile is created when user completes onboarding.
 * User profile will have data link PAN, CAN, NOMINEE, Bank details.
 *
 * This api simply gives MF-USER which is sibling of sam user or bam user and what action user can take on this platform
 *
 * @returns - mf user doc
 */
export async function fetchMfUser(): Promise<Mfuser['data']> {
  try {
    const response = await httpRequest({
      method: 'get',
      endpoint: apiMap.MF_USER,
      service: 'transaction',
    });

    const result = mfUserSchema.parse(response);

    return result['data'];
  } catch (err) {
    ErrorLogging.captureException(err, 'SINGLE_MUTUAL_FUND');
    throw err;
  }
}

const userProfileSchema = z.object({
  //! Marking these fields as optional because they are for display purposes only and we can show
  //! fallback copy / text in case they are not available for user instead of breaking the entire flow for them.
  bankDetails: z
    .object({
      accountNumber: z.string().optional(),
      ifsc: z.string().optional(),
      bankName: z.string().optional(),
      accountHolderName: z.string().optional(),
    })
    .optional(),
  phoneNumber: z.string().optional(),
});

export type UserProfileSchema = z.infer<typeof userProfileSchema>;

/**
 * function to fetch the user profile details like bank account details and phone number
 * @returns - mf user profile
 */
export async function fetchMfUserProfile(): Promise<UserProfileSchema> {
  try {
    const response = await httpRequest({
      method: 'get',
      endpoint: apiMap.USER_PROFILE,
      service: 'onboarding',
    });

    const result = userProfileSchema.parse(response);

    return result;
  } catch (err) {
    ErrorLogging.captureException(err, 'SINGLE_MUTUAL_FUND');
    throw err;
  }
}
