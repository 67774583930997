import ky, { HTTPError, type Options } from 'ky';
import { ObjectValues } from '@smallcase/types';
import { getSessionHeaders } from '@/utils/authUtils';
import { getApiRouteWithPathParams, type PathParams } from '@smallcase/utils';
import HttpRequestError from './HttpRequestError';

export const SERVICES = {
  onboarding: 'onboarding',
  session: 'session',
  transaction: 'transaction',
} as const;

type Services = ObjectValues<typeof SERVICES>;

const getDefaultHeaders = (): Options['headers'] => {
  return {
    'Content-Type': 'application/json',
    'x-sc-source': 'web',
    'accept-encoding': 'gzip, br',
  };
};

/**
 * Strategy pattern function for deriving session headers for each service
 * @param service - service for which session headers are required
 * @returns - Session headers for a given service
 */
const getSessionHeadersHandler = (): Options['headers'] => {
  return getSessionHeaders();
};

/**
 * Factory to generate the headers for a given service
 * @param service - the service for which the headers are required
 * @param additionalHeaders - additional headers which are required by client for their api
 * @returns - headers for a service
 */
const getHeaders = (
  additionalHeaders: Options['headers'],
  fileUpload: boolean,
): Options['headers'] => {
  return {
    ...(fileUpload ? {} : getDefaultHeaders()),
    ...getSessionHeadersHandler(),
    ...additionalHeaders,
  };
};

/**
 * factory function to get the base url for the given service
 * @param service - service for which api call is being made
 * @returns - base url for a given service
 */
const getPrefixUrl = (service: Services): string => {
  switch (service) {
    case SERVICES.onboarding:
      // Asserting a ! here because this value is not nullable and will be provided by the env file
      return process.env.APP_MFO_API_URL!;
    case SERVICES.session:
    case SERVICES.transaction:
      // Asserting a ! here because this value is not nullable and will be provided by the env file
      return process.env.APP_MFT_API_URL!;
    default:
      throw new Error('Invalid service');
  }
};

type Params = {
  /** The endpoint of the api call, it should not have a prefix `/` */
  endpoint: string;
  /** Type of api call, either mfo/mft OR session */
  service: Services;
  /**
   * The params for the path
   * eg: path -> `user/:username/investment`, here the `:username` is pathParam
   * { ':username': 'booh' }
   */
  pathParams?: PathParams;
  /** flag to check if sending the file */
  fileUpload?: boolean;
} & Pick<
  Options,
  | 'body'
  | 'headers'
  | 'json'
  | 'method'
  | 'searchParams'
  | 'signal'
  | 'retry'
  | 'timeout'
>;

const defaultKyOptions: Options = {
  cache: 'no-cache',
  keepalive: false,
};

const httpClient = ky.create(defaultKyOptions);

export async function httpRequest({
  endpoint,
  method,
  service,
  headers,
  pathParams,
  fileUpload = false,
  ...rest
}: Params) {
  const prefixUrl = getPrefixUrl(service);
  const urlEndpoint = getApiRouteWithPathParams({
    route: endpoint,
    pathParams,
  });
  const reqHeaders = getHeaders(headers, fileUpload);

  try {
    const response = await httpClient(urlEndpoint, {
      method,
      prefixUrl,
      headers: reqHeaders,
      ...rest,
    });

    const data = response.json();

    return data;
  } catch (err) {
    if (err instanceof HTTPError) {
      const error = await err.response.json();

      throw new HttpRequestError(
        err.message,
        error,
        err.stack,
        err.request.headers.get('x-mf-jwt'),
      );
    }

    throw err;
  }
}

/**
 * Service to make the cancellable http request
 * @param args - the arguments for the http request
 */
export function cancellableHttpRequest(request: Omit<Params, 'signal'>) {
  const abortController: AbortController = new AbortController();

  return {
    cancelRequest: abortController.abort,
    cancelled: abortController.signal.aborted,
    sendRequest: () =>
      httpRequest({
        ...request,
        signal: abortController.signal,
      }),
  };
}
