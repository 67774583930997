import { OnboardingStatuses } from '@/Onboarding/analytics/constants';
import { TrackOrderTypes } from '@/SingleMutualFunds/analytics/constants';
import { transactionType } from '@/constants/orderConstants';
import { ObjectValues } from '@smallcase/types';

/**
 * Not moving this file to /constants.ts because of a cyclic dep issue
 */

export const SharedErrorEvents = {
  GENERIC_ERROR: 'MF - Error',
} as const;
export type SharedErrorEvents = ObjectValues<typeof SharedErrorEvents>;

/** Shared across all the txn that will be tracked, either Onboarding | mf-Txn */
export const SharedTrackingEvents = {
  ...SharedErrorEvents,
} as const;
export type SharedTrackingEvents = ObjectValues<typeof SharedErrorEvents>;

export const FlowName = {
  [transactionType.ONBOARDING]: 'mf-onboarding',
  [transactionType.SINGLE_MUTUAL_FUND]: 'single-mf-orderflow',
  [transactionType.SMALLCASE_OF_MUTUAL_FUNDS]: 'smallcase-mf-orderflow',
  [transactionType.MANDATE]: 'mf-mandate',
  UNKNOWN: 'unknown-flow',
} as const;

export type FlowName = ObjectValues<typeof FlowName>;

export const SharedAllowedProperties = {
  flowName: 'flowName',
  errorType: 'errorType',
  errorMessage: 'errorMessage',
  errorInfo: 'errorInfo',
  error: 'error',

  onboardingStatus: 'onboardingStatus',
  orderType: 'orderType',
} as const;

export const MfErrorType = {
  /** Something broke on frontend */
  feError: 'feError',
  /** Something broke on backend/3rd party service provider */
  apiError: 'apiError',
  /** Something broke somewhere, usually the errors caught in try catch block with API calls and FE code as well */
  unknown: 'unknown',
} as const;
export type MfErrorType = ObjectValues<typeof MfErrorType>;

export type SharedAllowedPropertiesType = {
  /** Which flow the user is in */
  [SharedAllowedProperties.flowName]: FlowName;
  /** Cause of the Error */
  [SharedAllowedProperties.error]: string;
  /** Detailed description of the error, can include stacktrace as well */
  [SharedAllowedProperties.errorInfo]: string;
  /** What type of error it was, required types by analytics */
  [SharedAllowedProperties.errorType]: MfErrorType;
  /** Error message + description of the error */
  [SharedAllowedProperties.errorMessage]: string;
  /** If the user is onboarded or not, what's the state */
  [SharedAllowedProperties.onboardingStatus]: OnboardingStatuses;
  /** What is the orderType of the user's orderflow, will not be sent in case of onboarding */
  [SharedAllowedProperties.orderType]: TrackOrderTypes;
};
