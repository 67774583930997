import { getLastUsedTransactionId } from '@/utils/authUtils';
import { useParams } from 'react-router-dom';

export default function useTransactionId() {
  const { transactionId } = useParams();
  const lastUsedTransactionId = getLastUsedTransactionId();

  if (!transactionId) {
    throw new Error('Transaction Id is not available');
  }

  return {
    transactionId,
    lastUsedTransactionId,
    transactionIsSameAsLastUsedTransactionId:
      transactionId === lastUsedTransactionId,
  };
}
