import React, { Suspense } from 'react';
import SceneLoader from '@/components/SceneLoader/SceneLoader';
import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary';

type Props = {
  children: React.ReactNode;
};

/**
 * A Component to wrap the lazily loaded scenes with error boundary and suspense
 * @param props - props required for rendering the children
 * @returns Suspense and error boundary wrapper for scene
 */
export default function LazyLoadedScene(props: Props) {
  return (
    <ErrorBoundary>
      <Suspense fallback={<SceneLoader />}>{props.children}</Suspense>
    </ErrorBoundary>
  );
}
