export const colorWhite = "#ffffff";
export const colorWhiteRgb = "255, 255, 255";
export const colorBlack = "#000000";
export const colorBlue = "#1f7ae0";
export const colorBlueRgb = "31, 122, 224";
export const colorGold = "#e3af64";
export const colorGoldLight = "#fdce58";
export const colorBlueDark = "#2d3486";
export const colorBlueLight = "#668bdd";
export const colorOrangeLight = "#f4c55b";
export const colorGreyLight = "#f4f4f5";
export const linkHover = "#248aff";
export const colorGreen = "#19af55";
export const colorGreenRgb = "25, 175, 85";
export const colorRed = "#d82f44";
export const colorRedRgb = "216, 47, 68";
export const colorDanger = "#ff5942";
export const textColorDark = "#2f363f";
export const textColorDarkTransparent = "#2f363f00";
export const textColorNormal = "#535b62";
export const textYellow = "#ffc20c";
export const textColorLight = "#81878c";
export const textColorLightRgb = "129, 135, 140";
export const colorBorder = "#dde0e4";
export const colorBorderLight = "rgba(221, 224, 228, 0.5)";
export const colorBorderSlate = "#2f363f77";
export const hoverBg = "rgba(129, 135, 140, 0.08)";
export const bannerBg = "#f9fafb";
export const headerBg = "#ffffff";
export const headerLink = "#81878c";
export const headerLinkActive = "#2f363f";
export const headerLinkActiveMob = "#1f7ae0";
export const backgroundGrey = "#eeeeee";
export const backgroundCyan = "#e1f3ff";
export const colorYellow = "#f5a623";
export const colorScSpecialsLight = "rgba(68, 79, 211, 0.08)";
export const colorScSpecialsDark = "#444fd3";
export const colorScSpecialsDarkRgb = "68, 79, 211";
export const btnGreen = "#27bc94";
export const btnGreenRgb = "39, 188, 148";
export const btnHoverGreen = "#27c59a";
export const btnBlue = "#1f7ae0";
export const btnBlueRgb = "31, 122, 224";
export const btnHoverBlue = "#2685ef";
export const btnRed = "#d82f44";
export const btnRedRgb = "216, 47, 68";
export const btnHoverRed = "#d82f46";
export const lowRiskBg = "#cbeee4";
export const lowRiskBorder = "#25cea0";
export const moderateRiskBg = "#fff2d1";
export const moderateRiskBorder = "#f8dc94";
export const highRiskBgLight = "#fdf3f4";
export const highRiskBg = "#ffdeda";
export const highRiskBorder = "#ff9e94";
export const backgroundBlue = "#f3f8ff";
export const backgroundVeryLightBlue = "#f9faff";
export const modalGradient = "linear-gradient(0deg, #f9fafc 10%, white 20%, white 100%)";
export const modalHeaderBackground = "#f8f9fa";
export const mobileFooterCardShadow = "0 0 8px 0 rgba(0, 0, 0, 0.16)";
export const colorBlue500 = "#79afec";
export const colorBlue300 = "#d2e4f9";
export const colorYellow600 = "#ffc20c";
export const colorSuccess = "#27bc94";
export const colorHorizonSc = "#006c77";
export const bgControlSelected = "rgba(31, 122, 224, 0.1)";
export const bgControlHover = "rgba(151, 151, 151, 0.1)";
export const backgroundPurple = "#f8f5ff";
export const iconBgYellow = "#fef9eb";
export const bgOverlayLight = "rgba(255, 255, 255, 0.85)";
export const bgOverlayDark = "rgb(0 0 0 / 35%)";
export const colorBlueAqua = "#1a9dd8";
export const colorPaleGray = "#f9fafc";
export const hoverBgYellow = "#fffbf1";
export const colorDangerRgb = "255, 89, 66";
export const colorCornSilk = "#fff8e2";
export const colorPrimaryAccent400 = "#615bdd";
export const colorPrimaryAccent700 = "#1e196a";
export const pendingBgHover = "#fff8e2";
export const pendingBgNormal = "#fffcf3";
export const yellowBorder = "#ffedb1";
export const colorGoldRgb = "227, 175, 100";
export const colorGhostWhite = "#f2f6ff";
export const colorShadow = "rgba(0, 0, 0, 0.03)";
export const colorPaleGreen = "#19af551a";
export const colorCyanLight = "#bcd7f6";
export const colorGreyMedium = "#c1c4c6";
export const colorBoxShadow = "rgba(0, 0, 0, 0.08)";
export const textColorLightTransparent = "#565a5d";
export const colorBlueTransparent = "#edf4fd";
export const placeholderTextColor = "#dfe1e2";
export const hide = "-1";
export const auto = "'auto'";
export const base = "0";
export const docked = "10";
export const dropdown = "1000";
export const banner = "1100";
export const sticky = "1200";
export const overlay = "1300";
export const modal = "1400";
export const popover = "1500";
export const skiplink = "1600";
export const toast = "1700";
export const tooltip = "1800";
