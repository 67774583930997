import { ErrorLogging } from '@/errorReporting/sentry/logger';
import {
  createOrder,
  fetchOrderStatus,
  verifyOtp,
  executeOrder,
} from './atomicServices';

export type CreateOrderParams = Parameters<typeof createOrder>[0];

/**
 * service to create the order for a given mutual fund and return the status details
 * @param param - param required for creating the order
 * @returns status and order id for created order
 */
export async function createOrderService(params: CreateOrderParams) {
  try {
    const createdOrder = await createOrder(params);
    const status = await fetchOrderStatus(createdOrder);
    return { ...createdOrder, ...status };
  } catch (err) {
    ErrorLogging.captureException(err, 'SINGLE_MUTUAL_FUND');
    throw err;
  }
}

export type VerifyOrderOtpParams = Parameters<typeof verifyOtp>[0];

/**
 * service to verify the otp for a given order and return the status details
 * @param params - params required for verifying the otp
 * @returns - status of the journey and otp verified details
 */
export async function verifyOtpService(params: VerifyOrderOtpParams) {
  try {
    await verifyOtp(params);
    const status = await fetchOrderStatus({ orderId: params.orderId });
    return status;
  } catch (err) {
    ErrorLogging.captureException(err, 'SINGLE_MUTUAL_FUND');
    throw err;
  }
}

export type ExecuteOrderServiceParams = Parameters<typeof executeOrder>[0];

/**
 * service to execute the order and return status of the order
 * @param params - params required to execute the order
 * @returns - status of the order execution and order execution response
 */
export async function executeOrderService(params: ExecuteOrderServiceParams) {
  try {
    const executedOrderResponse = await executeOrder(params);
    const status = await fetchOrderStatus(params);

    return {
      ...executedOrderResponse,
      ...status,
    };
  } catch (err) {
    ErrorLogging.captureException(err, 'SINGLE_MUTUAL_FUND');
    throw err;
  }
}
