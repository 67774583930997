import {
  transactionOrderTypes,
  intents,
  transactionOrderConfigTypes,
} from '@/gatewayApp/constants';
import { z } from 'zod';

/* ------------- !SECTION - getTransactionDetails -------------- */

const transactionStatus = [
  'COMPLETED',
  'ERRORED',
  'ACTION_REQUIRED',
  'CANCELLED',
  'INITIALIZED',
  'PROCESSING',
  'USED',
];

type TransactionStatusIndividual = (typeof transactionStatus)[0];

const transactionStatusWithType: [
  TransactionStatusIndividual,
  ...TransactionStatusIndividual[],
] = [transactionStatus[0], ...transactionStatus.slice(1)];

const SipConfigSchema = z.array(
  z.object({
    mfId: z.string(),
    amount: z.number(),
  }),
);

const trxOrderTypesArray = Object.values(transactionOrderTypes);
type TrxOrderTypeIndividual = (typeof trxOrderTypesArray)[0];
const trxOrderTypesArrayWithType: [
  TrxOrderTypeIndividual,
  ...TrxOrderTypeIndividual[],
] = [trxOrderTypesArray[0], ...trxOrderTypesArray.slice(1)];

const OrderSchema = z.object({
  mfId: z.string(),
  price: z.number(),
  type: z.enum(trxOrderTypesArrayWithType),
});

export type OrderSchemaResponse = z.infer<typeof OrderSchema>;

/** BASE */
const BaseOrderConfig = z.object({
  assetUniverse: z.literal('MUTUAL_FUND'),
  name: z.optional(z.string()),
});

const TransactionConfig = z.object({
  orderConfig: BaseOrderConfig.extend({
    type: z.literal(transactionOrderConfigTypes.SECURITIES),
    orders: z.array(OrderSchema),
    sipConfig: z.undefined(),
  }),
});

const MandateConfig = z.object({
  orderConfig: BaseOrderConfig.extend({
    type: z.literal(transactionOrderConfigTypes.NEW),
    orders: z.undefined(),
    sipConfig: z.undefined(),
  }),
});

const SipConfig = z.object({
  orderConfig: BaseOrderConfig.extend({
    type: z.literal(transactionOrderConfigTypes.SECURITIES),
    orders: z.optional(z.array(OrderSchema)), // REVIEW once BE implements
    sipConfig: SipConfigSchema,
  }),
});

const OrderConfigSchema = z.discriminatedUnion('intent', [
  TransactionConfig.extend({
    intent: z.literal(intents.TRANSACTION),
  }),
  MandateConfig.extend({
    intent: z.literal(intents.MANDATE),
  }),
  SipConfig.extend({
    intent: z.literal(intents.SIP_SETUP),
  }),
  z.object({
    intent: z.literal(intents.ONBOARDING),
  }),
]);

export const transactionDetailsResponseSchema = z.object({
  data: z.intersection(
    z.object({
      // amcInfo: z.object({ // REVIEW need
      //   amcCode:
      //   name:
      // })
      error: z.optional(
        z.object({
          value: z.boolean(),
          // errorCode? // TODO ?
        }),
      ),
      expireAt: z.string(),
      // gateway: z.string(), // TODO
      intendedActionInitiated: z.boolean(),
      meta: z.object({
        name: z.string(),
      }),

      status: z.enum(transactionStatusWithType),
      transactionId: z.string(),
    }),
    OrderConfigSchema,
  ),
  success: z.boolean(),
});

export type TransactionDetailsResponse = z.infer<
  typeof transactionDetailsResponseSchema
>;
