import {
  CommonTrackingProperties,
  CommonTrackingPropertiesType,
} from '@/analytics/constants';
import { ObjectValues } from '@smallcase/types';

const OnboardingAllowedProperties = {
  ...CommonTrackingProperties,
  kycFetched: 'kycFetched',
  occupation: 'occupation',
  incomeBracket: 'incomeBracket',
  nomineeAdded: 'nomineeAdded',
  bankName: 'bankName',
  bankVerificationError: 'bankVerificationError',
  bankVerified: 'bankVerified',
  onboardingStatus: 'onboardingStatus',

  bankInfoPreFilled: 'bankInfoPreFilled',

  kycErrorType: 'kycErrorType',
  mfId: 'mfId',
  schemeType: 'schemeType',
  schemeCode: 'schemeCode',
  assetType: 'assetType',
  mfName: 'mfName',
  mfcAccountExists: 'mfcAccountExists',
} as const;
export interface OnboardingTrackingProperties
  extends CommonTrackingPropertiesType {
  /** If the mandate exists or not */
  [OnboardingAllowedProperties.kycFetched]: boolean;
  /** Can't have enums as these are governed by BE dynamically */
  [OnboardingAllowedProperties.occupation]: string;
  /** Can't have enums as these are governed by BE dynamically */
  [OnboardingAllowedProperties.incomeBracket]: string;
  [OnboardingAllowedProperties.nomineeAdded]: boolean;
  [OnboardingAllowedProperties.bankName]: string;
  [OnboardingAllowedProperties.bankVerificationError]: string;
  [OnboardingAllowedProperties.bankVerified]: boolean;
  /** Completed / Pending / Failed / InProgress */
  [OnboardingAllowedProperties.onboardingStatus]: OnboardingStatuses;

  [OnboardingAllowedProperties.bankInfoPreFilled]: boolean;

  [OnboardingAllowedProperties.kycErrorType]: string;
  [OnboardingAllowedProperties.mfId]: string;
  [OnboardingAllowedProperties.schemeType]: string;
  [OnboardingAllowedProperties.schemeCode]: string;
  [OnboardingAllowedProperties.mfName]: string;
  [OnboardingAllowedProperties.mfcAccountExists]: boolean;
}

export const OnboardingStatuses = {
  completed: 'Completed',
  pending: 'Pending',
  failed: 'Failed',
  inProgress: 'InProgress',
} as const;

export type OnboardingStatuses = ObjectValues<typeof OnboardingStatuses>;

export const UserProperties = [
  OnboardingAllowedProperties.kycFetched,
  OnboardingAllowedProperties.occupation,
  OnboardingAllowedProperties.incomeBracket,
  OnboardingAllowedProperties.bankName,
  OnboardingAllowedProperties.bankVerified,
  OnboardingAllowedProperties.nomineeAdded,
  OnboardingAllowedProperties.onboardingStatus,
  OnboardingAllowedProperties.mfcAccountExists,
] as const;
export type UserProperties = Pick<
  OnboardingTrackingProperties,
  (typeof UserProperties)[number]
>;
