import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useResizeObserver } from '@/hooks/useResizeObserver';
import { useFocusTrap } from '@/hooks/useFocusTrap';
import { useClickAway } from 'react-use';
import useOnClickEsc from '@/hooks/useOnClickEsc';
import { Show } from '@smallcase/components';
import AnimateHeight, { type Height } from 'react-animate-height';
import { useBodyScrollLock } from '@/hooks/useBodyScrollLock';
import './Modal.css';

type Props = {
  /**
   * function to close the modal
   */
  onClose(): void;
  /**
   * boolean to decide whether to close the modal when user clicks outside of the modal
   */
  closeOnOutsideClick?: boolean;
  /**
   * boolean to decide whether to close the modal when user clicks on esc or not
   */
  closeOnEscClick?: boolean;
  /**
   * content of the modal
   */
  children: React.ReactNode;
  /**
   * classname to extend / override the default styles of the modal content
   */
  className?: string;
  /**
   * className to extend the styles of the container
   */
  containerClassName?: string;
  /**
   * prop for whether to show overlay or not
   */
  overlay?: boolean;
  /**
   * custom classes for overlay if required
   */
  overlayClassName?: string;
};

type ClickAwayEvent = Parameters<Parameters<typeof useClickAway>[1]>[0];

/**
 * Modal component to show content in modal view
 * @param props - props required by the modal
 * @returns Modal UI
 */
export default function Modal(props: Props) {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const contentRef = useRef<React.ElementRef<'div'>>(null);
  const [height, setHeight] = useState<Height>('auto');

  const onClickOutsideHandler = useCallback(
    (e: ClickAwayEvent) => {
      e.stopPropagation();

      if (props.closeOnOutsideClick) {
        props.onClose();
      }
    },
    [props],
  );

  const onClickEscHandler = useCallback(
    (e: KeyboardEvent) => {
      e.stopPropagation();

      if (props.closeOnEscClick) {
        props.onClose();
      }
    },
    [props],
  );

  const onResize = useCallback(() => {
    setHeight(contentRef.current?.clientHeight ?? 'auto');
  }, []);

  useClickAway(ref, onClickOutsideHandler);

  useOnClickEsc(onClickEscHandler);

  useResizeObserver(contentRef, onResize);

  useFocusTrap(contentRef.current);

  useBodyScrollLock();

  // focus the content on mount
  useEffect(() => {
    contentRef.current?.focus();
  }, []);

  return (
    <div
      styleName="modal-container"
      id="modal"
      className={props.containerClassName}
    >
      <Show when={Boolean(props.overlay)}>
        <div styleName="modal-overlay" className={props.overlayClassName} />
      </Show>

      <div ref={ref} styleName="modal-content" className={props.className}>
        <AnimateHeight
          contentRef={contentRef}
          height={height} // a11y: for making the content focusable by default on modal mount
          tabIndex={0}
        >
          {props.children}
        </AnimateHeight>
      </div>
    </div>
  );
}

Modal.defaultProps = {
  closeOnOutsideClick: true,
  closeOnEscClick: true,
  className: '',
  containerClassName: '',
  overlay: true,
  overlayClassName: '',
};
