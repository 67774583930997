import OnboardingRenderer from '@/Onboarding/OnboardingRenderer';
import SingleMutualFunds from '@/SingleMutualFunds/SingleMutualFunds';
import { transactionType as availableTransactions } from '@/constants/orderConstants';
import { useOrderFlow } from '@/store/orderFlow';

/**
 * function to listen to state changes and mount the appropriate transaction modals
 * @returns Type of transaction flow which needs to be rendered
 */
export default function OrderFlowListener() {
  const transactionType = useOrderFlow((state) => state.transactionType);

  switch (transactionType) {
    case availableTransactions.SINGLE_MUTUAL_FUND:
      return <SingleMutualFunds />;
    case availableTransactions.MANDATE:
      return <SingleMutualFunds />; // TODO mandate @Isha988
    case availableTransactions.ONBOARDING:
      return <OnboardingRenderer />;
    default:
      return null;
  }
}
