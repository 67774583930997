import React from 'react';
import Modal from '../Modal/Modal';
import { Heading, IconOnlyButton, Show } from '@smallcase/components';
import AmcImage from '../AmcImage';
import { exitWithUserCancelled } from '@/gatewayApp/utils/utils';
import { IconClose } from '@smallcase/icons';
import { textColorLight } from '@/constants/colors';
import MfuLogo from '@/assets/mfu-logo.svg';

import styles from './OrderModalInterstitial.css';

type OrderModalInterstitialUiProps = {
  /**
   * heading of the order modal interstitial
   */
  heading: string;
  /**
   * description for the current stage
   */
  description: React.ReactNode;
  /**
   * amc code to fetch the image of the amc and display to user
   */
  amcCode?: string;
  /**
   * actions for the order modal interstitial
   */
  actions?: React.ReactNode;
  /**
   * whether to show close button or not
   */
  showClose?: boolean;
  /**
   * whether to show transaction provider info or not
   */
  showPartnerInfo?: boolean;
};

/**
 * Function component to render the order modal interstitial Ui
 * @param props - props required to render the Ui
 * @returns UI part of the order modal interstitial
 */
function OrderModalInterstitialUi(props: OrderModalInterstitialUiProps) {
  return (
    <div styleName="container">
      <div styleName="main-layout">
        <Show when={!!props.showClose}>
          <IconOnlyButton
            styleName="close-action"
            onClick={exitWithUserCancelled}
            label="close transaction"
          >
            <IconClose size={12} color={textColorLight} />
          </IconOnlyButton>
        </Show>
        <AmcImage amcCode={props.amcCode} size={64} />
        <div className="mb24" />
        <Heading as="h2">{props.heading}</Heading>
        <div className="mb8" />
        <p className="text-14 lh-169">{props.description}</p>
        <Show when={!!props.actions}>
          <div className="flex mt16 full-width">{props.actions}</div>
        </Show>
      </div>
      <Show when={props.showPartnerInfo ?? false}>
        <div
          styleName="powered-by"
          className="flex-center text-light full-width"
        >
          Powered by{' '}
          <img styleName="provider-logo" src={MfuLogo} alt="MF Utilities" />
        </div>
      </Show>
    </div>
  );
}

OrderModalInterstitialUi.defaultProps = {
  amcCode: '',
  actions: null,
  showClose: true,
  showPartnerInfo: false,
};

type OrderModalInterstitialProps = OrderModalInterstitialUiProps;
type BaseProps = { overlay?: boolean; onClose?: () => void };

type Props = OrderModalInterstitialProps & BaseProps;

/**
 * Function component re-presenting the order modal interstitial state
 * @param props - props required to render the interstitial modal
 * @returns Interstitial ui for the order flow
 */
export default function OrderModalInterstitial(props: Props) {
  return (
    <Modal
      closeOnEscClick={false}
      closeOnOutsideClick={false}
      onClose={exitWithUserCancelled}
      overlay={props.overlay}
      className={styles.content}
    >
      <OrderModalInterstitialUi
        heading={props.heading}
        description={props.description}
        amcCode={props.amcCode}
        actions={props.actions}
        showClose={props.showClose}
        showPartnerInfo={props.showPartnerInfo}
      />
    </Modal>
  );
}

OrderModalInterstitial.defaultProps = {
  amcCode: '',
  overlay: true,
  onClose: () => {},
  actions: null,
  showClose: true,
  showPartnerInfo: false,
};
