/** Not moving this file to /constants.ts because of a cyclic dep in analytics/constants */
import { ObjectValues } from '@smallcase/types';

// IM (No Mandate)
export const InvestMoreEvents = {
  VIEWED_INVEST_POPUP: 'MF - Viewed Invest Popup',
  CONFIRMED_ORDER_TYPE: 'MF - Confirmed Order Type',
  INITIATED_ORDER_INTENT: 'MF - Initiated Order Intent',
  CONFIRMED_AMOUNT: 'MF - Confirmed Amount',
  VIEWED_PAYMENTS_PAGE: 'MF - Viewed Payments Page',
  TRANSFERRED_AMOUNT: 'MF - Transferred Amount',
  ORDER_STATUS_RECEIVED: 'MF - Order Status Received',
  VIEWED_ORDER_COMPLETION_SCREEN: 'MF - Viewed Order Completion Screen',
  CLICKED_SETUP_MANDATE: 'MF - Clicked Setup Mandate',
  // No postback from MFU
  PAYMENT_STATUS_UNKNOWN: 'MF - Payment Status Unknown',
  PAYMENT_STATUS_RECIEVED: 'MF - Order Payment Status Received',
  PLACE_ORDER: 'MF - Place Order',
} as const;

export const MandateExistEvents = {
  // IM (Mandate Exists)
  VIEWED_INVEST_POPUP: 'MF - Viewed Invest Popup',
  CONFIRMED_ORDER_TYPE: 'MF - Confirmed Order Type',
  INITIATED_ORDER_INTENT: 'MF - Initiated Order Intent',
  // When the user clicks on continue to confirm amount
  CONFIRMED_AMOUNT: 'MF - Confirmed Amount',
  PLACE_ORDER: 'MF - Place Order',
  // happens in gateway flow
  ORDER_STATUS_RECEIVED: 'MF - Order Status Received',
  // happen in experience flow - when SDK redirects the user to the app or experience property
  VIEWED_ORDER_COMPLETION_SCREEN: 'MF - Viewed Order Completion Screen',
} as const;

export const ExitEvents = {
  // Exit
  VIEWED_EXIT_POPUP: 'MF - Viewed Exit Popup',
  INITIATED_ORDER_INTENT: 'MF - Initiated Order Intent',
  CONFIRMED_AMOUNT: 'MF - Confirmed Amount',
  PLACE_ORDER: 'MF - Place Order',
  ORDER_STATUS_RECEIVED: 'MF - Order Status Received',
  VIEWED_ORDER_COMPLETION_SCREEN: 'MF - Viewed Order Completion Screen',
} as const;

export const OtpEvents = {
  // OTP Handling States
  CLICKED_RESEND_OTP: 'MF - Clicked Resend OTP',
  OTP_VERIFIED: 'MF - OTP Verified',
  OTP_ERROR: 'MF - OTP Error',
  VIEWED_OTP_VERIFICATION_SCREEN: 'MF - Viewed OTP Verification screen',
} as const;

// Other Errors Handling
export const ErrorHandlingEvents = {
  // This can happen during multiple error cases and across multiple order types. Both needs to be stored as event property.
  CLICKED_RETRY_PLACING_ORDER: 'MF - Clicked Retry Placing Order',
  // This can happen during IM (No Mandate) payment failures. Order Type and Error Type needs to be stored as event property.
  VIEWED_PAYMENT_FAILED_ERROR: 'MF - Viewed Payment Failed Error',
  // When user clicks on chat with us after seeing holdings not synced error.
  CLICKED_CHAT_WITH_US: 'MF - Clicked Chat With Us',
  // When the user enters invalid amount in sell orders
  EXIT_AMOUNT_VALIDATION_ERROR: 'MF - Exit Amount Validation Error',
  // When the user sees generic API error
  VIEWED_API_ERROR: 'MF - Viewed API Error',
  // When the user gets a validation error in buy order type
  INVEST_AMOUNT_VALIDATION_ERROR: 'MF - Invest Amount Validation Error',
} as const;

export const BuyEvents = {
  // When the user completes the first order ever in smallcase journey. (user level event)
  /** // TODO this event will be fired from SAM platform since we have the invested scheme info values there. */
  FIRST_PURCHASE: 'MF - First Purchase',
} as const;

// User properties
// mfOnboarded
// scMFInvested
// mfInvested

// SIP( No Mandate )
export const SipNoMandateEvents = {
  VIEWED_INVEST_POPUP: 'MF - Viewed Invest Popup',
  CONFIRMED_ORDER_TYPE: 'MF - Confirmed Order Type',
  VIEWED_START_SIP_POPUP: 'MF - Viewed Start SIP Popup',
  INITIATED_ORDER_INTENT: 'MF - Initiated Order Intent',
  CONFIRMED_AMOUNT: 'MF - Confirmed Amount',
  CLICKED_SETUP_MANDATE: 'MF - Clicked Setup Mandate',
  VERIFY_MANDATE_DETAILS: 'MF - Verify Mandate Details',
  MANDATE_VERIFICATION_SUCCESSFUL: 'MF - Mandate Verification Successful',
  SIP_STARTED: 'MF - SIP Started',
} as const;

// SIP( Mandate exist )
export const SipMandateEvents = {
  VIEWED_INVEST_POPUP: 'MF - Viewed Invest Popup',
  CONFIRMED_ORDER_TYPE: 'MF - Confirmed Order Type',
  VIEWED_START_SIP_POPUP: 'MF - Viewed Start SIP Popup',
  INITIATED_ORDER_INTENT: 'MF - Initiated Order Intent',
  CONFIRMED_AMOUNT: 'MF - Confirmed Amount',
  SELECTED_SIP_FREQUENCY: 'MF - Selected SIP Frequency',
  PLACE_ORDER: 'MF - Place Order',
  SIP_STARTED: 'MF - SIP Started',
} as const;

// Edit SIP - (SIP Active)
export const EditSipEvents = {
  CLICKED_EDIT_SIP: 'MF - Clicked Edit SIP',
  INITIATED_ORDER_INTENT: 'MF - Initiated Order Intent',
  CONFIRMED_AMOUNT: 'MF - Confirmed Amount',
  PLACE_ORDER: 'MF - Place Order',
  SIP_UPDATED: 'MF - SIP Updated',
} as const;

// IM - (SIP Active, Mandate Exist)
export const InvestMoreSipMandateEvents = {
  VIEWED_INVEST_POPUP: 'MF - Viewed Invest Popup',
  INITIATED_ORDER_INTENT: 'MF - Initiated Order Intent',
  CONFIRMED_AMOUNT: 'MF - Confirmed Amount',
  PLACE_ORDER: 'MF - Place Order',
  ORDER_STATUS_RECEIVED: 'MF - Order Status Received',
} as const;

// End SIP( Mandate exist )
export const EndSipMandateEvents = {
  CLICKED_EDIT_SIP: 'MF - Clicked Edit SIP',
  INITIATED_ORDER_INTENT: 'MF - Initiated Order Intent',
  CLICKED_END_SIP: 'MF - Clicked End SIP',
  SIP_ENDED: 'MF - SIP Ended',
  CLICKED_CONTINUE_SIP: 'MF - Clicked Continue SIP',
  ORDER_STATUS_RECEIVED: 'MF - Order Status Received',
} as const;

export const OrderflowTrackingEvents = {
  ...InvestMoreEvents,
  ...MandateExistEvents,
  ...ExitEvents,
  ...OtpEvents,
  ...ErrorHandlingEvents,
  ...BuyEvents,
  ...SipNoMandateEvents,
  ...SipMandateEvents,
  ...EditSipEvents,
  ...InvestMoreSipMandateEvents,
  ...EndSipMandateEvents,
} as const;

export type OrderflowTrackingEvents = ObjectValues<
  typeof OrderflowTrackingEvents
>;
