import { sessionManagementServices } from '@/services';
import { ObjectValues } from '@smallcase/types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const authStatuses = {
  UNAUTHORIZED: 'UNAUTHORIZED',
  AUTHORIZED: 'AUTHORIZED',
  IN_PROGRESS: 'IN_PROGRESS',
  OTP_REQUIRED: 'OTP_REQUIRED',
} as const;

type AuthStatuses = ObjectValues<typeof authStatuses>;

type AuthState = {
  /**
   * auth status of the user
   * 1. IN_PROGRESS means we are performing the session check and user is neither logged out or logged in yet. The check is going on
   * 2. AUTHORIZED means user has a valid session and can proceed with using the platform
   * 3. UNAUTHORIZED means session is invalid and can't use the platform
   * 4. OTP_REQUIRED: when the user is valid but we want to validate the user
   */
  authStatus: AuthStatuses;

  /**
   * details of the user
   */
  userProfile: sessionManagementServices.UserProfileSchema &
    Partial<sessionManagementServices.Mfuser['data']>;
  /**
   * function to update the auth state of the user
   * @param authStatus - auth state of the user
   */
  setAuthStatus(authStatus: AuthStatuses): void;

  /**
   * setter function to update the profile data of the user
   * @param userProfile - profile details of the user
   */
  setUserProfile(userProfile: AuthState['userProfile']): void;

  /**
   * function to update the auth store at once and prevent multiple re-renders
   * @param data - auth status and user profile of the user
   */
  setState(data: Pick<AuthState, 'authStatus' | 'userProfile'>): void;
};

export const useAuthStore = create<AuthState>()(
  devtools(
    (set, get) => ({
      authStatus: authStatuses.IN_PROGRESS,
      userProfile: {
        bankDetails: undefined,
        phoneNumber: undefined,
        onboarding: undefined,
      },
      setAuthStatus(authStatus: AuthStatuses) {
        set({ authStatus });
      },
      setUserProfile(userProfile: AuthState['userProfile']) {
        set({ userProfile: { ...get().userProfile, ...userProfile } });
      },
      setState(data: Pick<AuthState, 'authStatus' | 'userProfile'>) {
        set({
          authStatus: data.authStatus,
          userProfile: {
            ...get().userProfile,
            ...data.userProfile,
          },
        });
      },
    }),
    { name: 'AUTH_STORE', anonymousActionType: 'AUTH_STORE' },
  ),
);
