import OrderModalInterstitial from '@/components/OrderModalInterstitial/OrderModalInterstitial';
import { TransactionDetailsResponse } from '@/services/gateway/responseSchema';
import { OrderflowDetails, useOrderFlow } from '@/store/orderFlow';
import { useCallback, useEffect } from 'react';
import {
  exitWithInternalError,
  getOrderTypeFromTransactionOrdersType,
  getTransactionTypeFromIntent,
} from './utils/utils';
// import useTransactionDetails from './hooks/useTransactionDetails';
import { orderType, transactionType } from '@/constants/orderConstants';
import { gatewayServices } from '@/services';
import { useAuthStore } from '@/store/auth';
import { intents, transactionOrderConfigTypes } from './constants';

/**
 * This component fetches the Transaction details based on the `TRX_ID`
 * (decoded from JWT at platform) and based on the conditions, sets the proper state in
 * the `orderFlowStore` which then mounts/redirects the user to the appropriate orderflow/flow.
 * @returns Loader state JSX
 */
export default function Transactions() {
  const setOrderFlowDetails = useOrderFlow(
    (state) => state.setOrderFlowDetails,
  );

  const onboardingRequired: boolean = useAuthStore(
    (state) => state.userProfile.onboarding?.required ?? false,
  );

  const mountedTransactionType = useOrderFlow((state) => state.transactionType);

  /** Set intents and orderConfig in `useOrderFlow` store */
  const setDetailsInStore = useCallback(
    (trxResponseData: TransactionDetailsResponse['data']) => {
      let orderFlowDetails: OrderflowDetails = {
        mfId: '',
        orderType: orderType.NONE,
        transactionType: transactionType.NONE,
      };

      if (
        trxResponseData.intent === intents.TRANSACTION &&
        trxResponseData.orderConfig.type ===
          transactionOrderConfigTypes.SECURITIES
      ) {
        // onBoarding, IM, Exit, Transaction
        if (
          Array.isArray(trxResponseData.orderConfig.orders) &&
          trxResponseData.orderConfig.orders.length > 0
        ) {
          // Picking the topMost order. // REVIEW in case of mfSmallcase
          const firstOrder = trxResponseData.orderConfig.orders[0];

          orderFlowDetails = {
            mfId: firstOrder.mfId,
            orderType: getOrderTypeFromTransactionOrdersType(firstOrder.type),
            transactionType: getTransactionTypeFromIntent(
              trxResponseData.intent,
            ),
          };
        }
      }

      if (
        trxResponseData.intent === intents.MANDATE &&
        trxResponseData.orderConfig.type === transactionOrderConfigTypes.NEW
      ) {
        orderFlowDetails = {
          mfId: '',
          orderType: orderType.MANDATE,
          transactionType: getTransactionTypeFromIntent(trxResponseData.intent),
        };
      }

      if (trxResponseData.intent === intents.SIP_SETUP) {
        // sipSetup flow
      }

      // Explicitly check if onboarding is the intent, if its true, no need to go further and simply start the flow from here
      if (trxResponseData.intent === intents.ONBOARDING) {
        setOrderFlowDetails({
          mfId: '',
          orderType: orderType.NONE,
          transactionType: transactionType.ONBOARDING,
        });

        return;
      }

      // This check is for chaining the onboarding with the order flow user intended to open
      if (onboardingRequired) {
        setOrderFlowDetails({
          mfId: '',
          orderType: orderType.NONE,
          transactionType: transactionType.ONBOARDING,
          orderStack: [orderFlowDetails],
        });
      } else {
        setOrderFlowDetails({ ...orderFlowDetails });
      }
    },
    [onboardingRequired, setOrderFlowDetails],
  );

  useEffect(() => {
    /** get trx details from -> mf/gateway/transaction */
    gatewayServices
      .getTransactionDetails()
      .then((transactionDetailsResponse) => {
        /** store it in `useOrderFlow` store if transaction API call is success. */
        if (transactionDetailsResponse.success) {
          setDetailsInStore(transactionDetailsResponse.data);
        } else {
          throw Error('Api failure');
        }
      })
      .catch((err) => {
        console.log(err);
        exitWithInternalError();
      });
  }, [setDetailsInStore]);

  // In case Transaction is not none it means an order flow is mounted and we need to hide the loader
  if (mountedTransactionType !== transactionType.NONE) {
    return null;
  }

  return (
    <OrderModalInterstitial
      heading="Preparing your order..."
      description="Please wait while we bring you awesomeness"
      overlay={false}
      showClose={false}
      showPartnerInfo
    />
  );
}
