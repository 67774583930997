import React from 'react';
import { useSession } from '@/hooks/useAuth';
import { authStatuses } from '@/store/auth';
import { SmallcaseLoader } from '@smallcase/components';
import { markTransactionAsExpired } from '@/gatewayApp/utils/utils';

type Props = {
  children: React.ReactNode;
};

export default function PrivateRoute(props: Props) {
  const { authStatus } = useSession();

  if (authStatus === authStatuses.IN_PROGRESS) {
    return <SmallcaseLoader />;
  }

  if (authStatus === authStatuses.UNAUTHORIZED) {
    markTransactionAsExpired();
  }

  return <>{props.children}</>;
}
