import React, { Suspense } from 'react';
import { orderType } from '@/constants/orderConstants';
import { useOrderFlow } from '@/store/orderFlow';
import OrderModalInterstitial from '@/components/OrderModalInterstitial/OrderModalInterstitial';
import TrackingContextProvider from './analytics/TrackingContext/OrderflowTrackingContext';

const ExitRenderer = React.lazy(() => import('./Exit/ExitRenderer'));
const InvestMoreRenderer = React.lazy(
  () => import('./InvestMore/InvestMoreRenderer'),
);

/**
 * Functional Component which contains rendering logic for single mutual funds order flows
 * @returns Single Mutual Funds Order Flows
 */
export default function SingleMutualFunds() {
  const type = useOrderFlow((state) => state.orderType);

  switch (type) {
    case orderType.EXIT:
      return (
        <Suspense
          fallback={
            <OrderModalInterstitial
              description="Please wait while we bring awesomeness"
              heading="Preparing your order..."
              overlay={false}
              showClose={false}
              showPartnerInfo
            />
          }
        >
          <TrackingContextProvider>
            <ExitRenderer />
          </TrackingContextProvider>
        </Suspense>
      );
    case orderType.INVESTMORE:
      return (
        <Suspense
          fallback={
            <OrderModalInterstitial
              description="Please wait while we bring awesomeness"
              heading="Preparing your order..."
              overlay={false}
              showClose={false}
              showPartnerInfo
            />
          }
        >
          <TrackingContextProvider>
            <InvestMoreRenderer />
          </TrackingContextProvider>
        </Suspense>
      );
    default:
      return null;
  }
}
