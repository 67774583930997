import React, { useCallback, useEffect, useRef } from 'react';

import * as orderflowEvents from '../eventNames';
import analyticsManager from '@/analytics/analyticsManager';
import { AnalyticsExtraProps, NOT_AVAILABLE } from '@/analytics/constants';
import { ObjectValues } from '@smallcase/types';
import { useMutualFundTransactionalInfo } from '@/store/mutualFundTransactionInfo';
import { useOrderFlow } from '@/store/orderFlow';
import {
  OrderflowTrackingStore,
  TxnTrackProps,
  useTxnTrackingInfo,
} from '@/store/transactionTrackingInfo';
import { createObjFromKeyArray } from '@/analytics/analyticsUtils';

/** Superset of all orderflow events */
type OrderflowEventNames = ObjectValues<
  typeof orderflowEvents.OrderflowTrackingEvents
>;
/** All the tracking properties of orderflow */
type OrderflowExtraProperties = Partial<TxnTrackProps> | AnalyticsExtraProps;

type TrackOrderflowParam<T> =
  | {
      /** Event to be tracked */
      eventName: T;
      extraProperties?: undefined;
    }
  | {
      /** Event to be tracked */
      eventName: T;
      /** Any extra event properties to be tracked */
      extraProperties: OrderflowExtraProperties;
      /** Array of the keys of those extra event properties to be stored in zustand that are being tracked */
      savePropertiesToStore?: Array<keyof OrderflowExtraProperties>;
    };

type OrderflowContextType = {
  trackInvestMoreEvent: (
    param: TrackOrderflowParam<
      | ObjectValues<typeof orderflowEvents.InvestMoreEvents>
      | ObjectValues<typeof orderflowEvents.BuyEvents> // Added it here since whole flow is same, buy just has one event (if it's 1st time buy)
    >,
  ) => void;
  trackExitEvent: (
    param: TrackOrderflowParam<ObjectValues<typeof orderflowEvents.ExitEvents>>,
  ) => void;
  trackOrderflowEvent: (
    param: TrackOrderflowParam<OrderflowEventNames>,
  ) => void;
  setTrackingDetails: OrderflowTrackingStore['setTrackingDetails'];
};
export const OrderflowTrackingContext = React.createContext<
  OrderflowContextType | undefined
>(undefined);

OrderflowTrackingContext.displayName = 'OrderflowTrackingContext';

type Props = { children: JSX.Element | JSX.Element[] };

const baseProperties = {
  accessedFrom: NOT_AVAILABLE,
  sectionTag: NOT_AVAILABLE,
  deviceType: NOT_AVAILABLE,
  gatewayName: NOT_AVAILABLE,
  gatewayType: NOT_AVAILABLE,
  clientType: NOT_AVAILABLE,

  // Extra props
  orderType: NOT_AVAILABLE,
  whatsappConsent: NOT_AVAILABLE,
  mandateExists: NOT_AVAILABLE,
  isSIPSet: NOT_AVAILABLE,

  mfId: NOT_AVAILABLE,
  mfScheme: NOT_AVAILABLE,
  minInvestAmount: NOT_AVAILABLE,
  amountEntered: NOT_AVAILABLE,
  mfName: NOT_AVAILABLE,
  assetType: NOT_AVAILABLE,

  minSIPAmount: NOT_AVAILABLE, // Partial (not in all cases)
  maxExitAmount: NOT_AVAILABLE, // Partial (not in all cases)
  sellAllUnits: NOT_AVAILABLE, // Partial (not in all cases)
  errorType: NOT_AVAILABLE, // Partial (not in all cases)
} as const;

/**
 * As in V1, we do not support mandate and SIP, so setting them as false.
 */
const extendedBasePropertiesV1 = {
  ...baseProperties,
  whatsappConsent: false,
  mandateExists: false,
  isSIPSet: false,
} as const;

export default function TrackingContextProvider(props: Props): JSX.Element {
  const txnInfo = useMutualFundTransactionalInfo((state) => state);
  const orderflowData = useOrderFlow((state) => state);
  const trackingStoreData = useRef(useTxnTrackingInfo((state) => state));

  useEffect(() => {
    useTxnTrackingInfo.subscribe(
      // biome-ignore lint/suspicious/noAssignInExpressions: Have to check the actual usage
      (state) => (trackingStoreData.current = state),
    );
  }, []);

  // zustand store that has orderflow properties for tracking orderflow
  const { setTrackingDetails } = useTxnTrackingInfo();

  const getCommonProperties = useCallback(() => {
    if (orderflowData.mfId) {
      return {
        mfid: orderflowData.mfId ?? 'N/A',
        mfName: txnInfo.scheme.name ?? 'N/A',
        minInvestAmount: txnInfo.transactionalInfo?.buy.allowed
          ? txnInfo.transactionalInfo?.buy.minInvestmentAmount
          : NOT_AVAILABLE,
        // TODO here SIP amount has to be added 👇🏼
        minSIPAmount: txnInfo.transactionalInfo?.buy.allowed
          ? txnInfo.transactionalInfo?.buy.minInvestmentAmount // TODO change
          : NOT_AVAILABLE,

        // From tracking store
        amountEntered: trackingStoreData.current.amountEntered,
        orderType: trackingStoreData.current.orderType,
        mandateExists: trackingStoreData.current.mandateExists,
        isSIPSet: trackingStoreData.current.isSIPSet,
        sellAllUnits: trackingStoreData.current.sellAllUnits,
      };
    }

    return extendedBasePropertiesV1;
  }, [orderflowData.mfId, txnInfo.scheme.name, txnInfo.transactionalInfo?.buy]);

  /**
   * Tracks all the events of the invest more orderflow
   */
  const trackInvestMoreEvent = useCallback(
    (
      params: Parameters<OrderflowContextType['trackInvestMoreEvent']>[0],
    ): void => {
      try {
        analyticsManager.track(params.eventName, {
          ...getCommonProperties(),
          ...params.extraProperties,
        });

        /**
         * Creates an object of properties to set in store from the keys passed in `savePropertiesToStore`
         */
        const valuesToSetInStore = createObjFromKeyArray(
          // @ts-expect-error since orderflow takes it's own type safe extra props,
          // but below function takes more generic superset props, so error here is expected.
          params.extraProperties,
          params.extraProperties ? params.savePropertiesToStore : [],
        );

        if (valuesToSetInStore) {
          setTrackingDetails({
            ...valuesToSetInStore,
          });
        }
      } catch (e) {
        console.log('Something went wrong while tracking InvestMoreEvent: ', e);
      }
    },
    [getCommonProperties, setTrackingDetails],
  );

  /**
   * Tracks all the events of the invest more orderflow
   */
  const trackExitEvent = useCallback(
    (params: Parameters<OrderflowContextType['trackExitEvent']>[0]): void => {
      try {
        analyticsManager.track(params.eventName, {
          ...getCommonProperties(),
          ...params.extraProperties,
        });

        /**
         * Creates an object of properties to set in store from the keys passed in `savePropertiesToStore`
         */
        const valuesToSetInStore = createObjFromKeyArray(
          // @ts-expect-error since orderflow takes it's own type safe extra props,
          // but below function takes more generic superset props, so error here is expected.
          params.extraProperties,
          params.extraProperties ? params.savePropertiesToStore : [],
        );

        if (valuesToSetInStore) {
          setTrackingDetails({
            ...valuesToSetInStore,
          });
        }
      } catch (e) {
        console.log('Something went wrong while tracking ExitEvent: ', e);
      }
    },
    [getCommonProperties, setTrackingDetails],
  );

  /**
   * Tracks all the Common events of the orderflow
   */
  const trackOrderflowEvent = useCallback(
    (
      params: Parameters<OrderflowContextType['trackOrderflowEvent']>[0],
    ): void => {
      try {
        analyticsManager.track(params.eventName, {
          ...getCommonProperties(),
          ...params.extraProperties,
        });

        /**
         * Creates an object of properties to set in store from the keys passed in `savePropertiesToStore`
         */
        const valuesToSetInStore = createObjFromKeyArray(
          // @ts-expect-error since orderflow takes it's own type safe extra props,
          // but below function takes more generic superset props, so error here is expected.
          params.extraProperties,
          params.extraProperties ? params.savePropertiesToStore : [],
        );

        if (valuesToSetInStore) {
          setTrackingDetails({
            ...valuesToSetInStore,
          });
        }
      } catch (e) {
        console.log('Something went wrong while tracking OrdeflowEvent: ', e);
      }
    },
    [getCommonProperties, setTrackingDetails],
  );

  return (
    <OrderflowTrackingContext.Provider
      value={{
        trackInvestMoreEvent,
        trackExitEvent,
        trackOrderflowEvent,
        setTrackingDetails,
      }}
    >
      {props.children}
    </OrderflowTrackingContext.Provider>
  );
}

/**
 * this is used for testing purpose only like storybook
 */
export const OrderflowTrackingTestContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <OrderflowTrackingContext.Provider
      value={{
        trackInvestMoreEvent: () => {},
        trackExitEvent: () => {},
        trackOrderflowEvent: () => {},
        setTrackingDetails: () => {},
      }}
    >
      {children}
    </OrderflowTrackingContext.Provider>
  );
};
