import { TransactionType } from '@/constants/orderConstants';
import HttpRequestError from '@/httpService/HttpRequestError';
import * as Sentry from '@sentry/react';

function ErrorLoggingManager() {
  function setUser(id: string) {
    Sentry.setUser({ id });
  }

  function getScope() {
    return Sentry.getCurrentScope();
  }

  function setTag(key: string, value: string | number | boolean) {
    const scope = getScope();

    scope.setTag(key, value);
  }

  function setTransactionId(transactionId: string) {
    setTag('transactionId', transactionId);
  }

  function setBreadCrumbs(params: {
    category: TransactionType;
    message: string;
  }) {
    Sentry.addBreadcrumb({
      category: params.category,
      message: params.message,
    });
  }

  function captureException(err: unknown, category: TransactionType) {
    if (err instanceof HttpRequestError) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Sentry.setExtra('Error Response', JSON.stringify(err.data));
      Sentry.setExtra('Error Token', { jwt: err.jwt });
    }

    Sentry.captureException(err, {
      tags: {
        category,
      },
    });
  }

  return {
    setTag,
    setTransactionId,
    setBreadCrumbs,
    captureException,
    setUser,
  };
}

const ErrorLogging = ErrorLoggingManager();

export { ErrorLogging };
