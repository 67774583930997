import {
  init as sentryInit,
  browserTracingIntegration,
  replayIntegration,
} from '@sentry/react';
const env = process.env.ENV as string;
function initialize() {
  console.log('*** INITIALIZING SENTRY ***');
  sentryInit({
    dsn: 'https://6792945166a2df59ba9521abe17c11ce@sentry-dev.smallcase.com/4',
    integrations: [
      browserTracingIntegration({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'https://mf-stag.smallcase.com',
          'https://mf-dev.smallcase.com',
          'https://mf.smallcase.com',
          'https://smallcase.mfuonline.com',
          'https://mf-local.smallcase.com',
        ],
      }),
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkCaptureBodies: true,
        networkDetailAllowUrls: [
          'mfo.smallcase.com',
          'mfi-api.smallcase.com',
          'mfo.stag.smallcase.com',
          'investments-api-stag.smallcase.com',
        ],
        networkRequestHeaders: ['X-Mf-Jwt'],
      }),
    ],
    environment: env,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// initialize only if it's production or staging env
if (['production', 'staging'].includes(env)) {
  initialize();
}
