import React from 'react';
import { MobileContextDefaultProvider } from '@smallcase/context/MobileContext';
import { ToastContainer } from '@smallcase/toast';
import QueryProvider from './Providers/QueryProvider';
import Router from './Router/Router';

export default function App() {
  return (
    <QueryProvider>
      <MobileContextDefaultProvider>
        <ToastContainer />
        <Router />
      </MobileContextDefaultProvider>
    </QueryProvider>
  );
}
