import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

// Components
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import LazyLoadedScene from '@/components/LazyLoadedScene/LazyLoadedScene';
import OrderFlowListener from '@/OrderFlowListener/OrderFlowListener';
import FourOFour from '@/pages/FourOFour/FourOFour';
import Transactions from '@/gatewayApp/Transactions';
const KitchenSink = React.lazy(() => import('@/KitchenSink'));

const PaymentRedirect = React.lazy(
  () => import('@/pages/PaymentRedirectApp/PaymentRedirect'),
);

const Screener = React.lazy(() => import('@/pages/Screener/Screener'));

const appRouter = createBrowserRouter([
  {
    path: '/transaction/:transactionId',
    element: (
      <PrivateRoute>
        <LazyLoadedScene>
          <OrderFlowListener />
          <Transactions />
        </LazyLoadedScene>
      </PrivateRoute>
    ),
  },
  process.env.ENABLE_KITCHEN_SINK
    ? {
        path: `/kitchen-sink/:transactionId`,
        element: (
          <PrivateRoute>
            <LazyLoadedScene>
              <OrderFlowListener />
              <KitchenSink />
            </LazyLoadedScene>
          </PrivateRoute>
        ),
      }
    : {},
  {
    path: '/payment-redirect',
    element: (
      <LazyLoadedScene>
        <PaymentRedirect />
      </LazyLoadedScene>
    ),
  },
  {
    path: '/screener',
    element: (
      <LazyLoadedScene>
        <Screener />
      </LazyLoadedScene>
    ),
  },
  {
    path: '*',
    element: <FourOFour />,
  },
]);

/**
 * Router for the MF app
 * @returns router of the app
 */
export default function Router() {
  return <RouterProvider router={appRouter} />;
}
