import { AnalyticsLibraries, UserObjectType } from '../constants';

/**
 * base class for a traction library.
 * Every tracking library must extend this
 */
abstract class TrackingLibraryBase {
  abstract label: AnalyticsLibraries;

  protected isLibraryLoaded = false;

  /**
   * Imperative method to load the tracking library.
   * This should, in general, have code to load the tracking library scripts
   * This can take in an id and a method to be called when the script is loaded
   *
   * Please note that when the script is loaded vs when the library/widget is loaded
   * might be two different things.
   * For example, for some libraries, the base script then triggers some more script downloads
   * and the library would be considered loaded only
   * after all those subsequent scripts are downloaded and executed
   * But this method would be run when the first script is loaded.
   */
  abstract load(onLoad?: () => void): void;

  /**
   * used to notify to a library that a user has logged in.
   * It can use the provided data to initialize the user.
   */
  abstract setUser(userData: UserObjectType): void;

  /**
   * method used to send tracking events to the tracking library.
   * This will receive eventLabel and tracking data and other optional arguments
   */
  abstract track(eventLabel: string, data: object): void;

  /**
   * used to update user properties for a logged in user
   */
  abstract updateUser(data: Record<string, string | number | boolean>): void;

  /**
   * method used to denote that this library is now usable.
   * For some libraries, this can mean that the library methods can be called,
   * as a mock has been exposed on window, using some queuing mechanism - Intercom for example
   * but the scripts might not have loaded yet.
   * in such cases, the library is still usable in some way with limited functionality.
   * In some cases, it can be considered equivalent to isLoaded,
   * but in most cases isLoaded is a better indication of
   * when all the library functionality can be used.
   *
   * This method can be used to check whether a tracking library is available to be initialized
   * for some libraries it can happen before the actual script is loaded,
   * for example, intercom exposes window.Intercom and queues all the commands,
   * which means we can call init on intercom before the script has actually loaded
   * For some libraries which do not have this kind of setup,
   * we can't call the init if the library has not yet loaded
   * in such cases, the isAvailable and isLoaded would mostly provide similar information
   *
   * Currently we only use this method to check whether
   * we can call the init method on a library or not
   * But practically, this denotes whether we can call any methods on the library
   * irrespective of whether or not the actual script ahs loaded.
   * and so we can use it in a lot of other cases if required.
   */
  abstract isAvailable(): boolean;

  /**
   * used to reset any user related data
   * mostly used after logout
   */
  abstract reset(): void;

  /**
   * method which denotes that the library has now loaded all the required scripts
   * and is ready to track events
   */
  isLoaded(): boolean {
    return this.isLibraryLoaded;
  }

  /**
   * returns a label that uniquely identifies the tracking library
   */
  getLabel(): AnalyticsLibraries {
    return this.label;
  }

  /**
   * method which should be called after the tracking library is loaded,
   * to explicitly set is Loaded to true.
   * Different tracking libraries might have different ways to expose
   * whether or not they have loaded and are now ready to use
   * For example, some tracking libraries might be usable as soon as the script gets executed
   * some tracking libraries are usable after they have been initialized in code after script load,
   * some tracking libraries might download other scripts and send out a loaded event
   * after they are done loading.
   */
  protected setLoaded = () => {
    this.isLibraryLoaded = true;
  };
}

export default TrackingLibraryBase;
