import { ParsedUrlQuery } from 'querystring';
import {
  getLocalStorageItemWithExpiry,
  setLocalStorageItemWithExpiry,
} from '@smallcase/utils/browser';
import { NOT_AVAILABLE, UTM_LIFETIME, UTM_STORE_KEY } from './constants';

/** valid UTM keys that can be set in the URL */
enum UtmKeys {
  utmTerm = 'utm_term',
  utmSource = 'utm_source',
  utmMedium = 'utm_medium',
  utmContent = 'utm_content',
  utmCampaign = 'utm_campaign',
}

/** utm key<->value pair */
export type UtmObject = Record<UtmKeys, string>;

/** get default UTM values */
const getFallbackUtms = (): UtmObject => {
  return {
    utm_term: NOT_AVAILABLE,
    utm_source: NOT_AVAILABLE,
    utm_medium: NOT_AVAILABLE,
    utm_content: NOT_AVAILABLE,
    utm_campaign: NOT_AVAILABLE,
  };
};

/** given an object, picks valid keys and returns a valid utm object */
const pickValidUtmsKeys = (
  obj: Record<string, string | string[] | undefined>,
): UtmObject => {
  const utm = getFallbackUtms();

  Object.values(UtmKeys).forEach((utmKey) => {
    utm[utmKey] = obj[utmKey]?.toString().trim() || NOT_AVAILABLE;
  });

  return utm;
};

/**
 * read and initialize UTMs for a session
 *
 * - if the URL has some UTM params, then read and set those in local store
 * - if no UTM params are present, read local store of existing params
 * - if we find params, then set them as the active params
 * - if none were found (none were set or older one expired), then set default UTMs
 */
export const initUtmsFromParams = (queryParams: ParsedUrlQuery): UtmObject => {
  let utm: UtmObject = getFallbackUtms();

  try {
    /** this will be true if atleast one utm key is set in the URL */
    const hasUtmValues = Object.values(UtmKeys).some((utmKey) => {
      return Boolean(queryParams[utmKey]);
    });

    if (hasUtmValues) {
      utm = pickValidUtmsKeys(queryParams);
    } else {
      const storedUtms = getLocalStorageItemWithExpiry(UTM_STORE_KEY) || '{}';
      const parsedStoredUtms = JSON.parse(storedUtms);
      utm = pickValidUtmsKeys(parsedStoredUtms);
    }
  } catch (error) {
    console.error('unable to read utm params', error);
    utm = getFallbackUtms();
  } finally {
    setLocalStorageItemWithExpiry(
      UTM_STORE_KEY,
      JSON.stringify(utm),
      UTM_LIFETIME,
    );
  }

  return utm;
};

/**
 * read utm keys from local store
 */
export const getStoredUtmKeys = (): UtmObject => {
  const storeStr: string = getLocalStorageItemWithExpiry(UTM_STORE_KEY) || '{}';

  try {
    const utmFromStore: Record<string, string | undefined> =
      JSON.parse(storeStr);

    return pickValidUtmsKeys(utmFromStore);
  } catch {
    return getFallbackUtms();
  }
};

/**
 * map utm keys to event_ keys for better event tracking in CT and mixpanel
 */
export const getStoredUtmKeysAsEvent = () => {
  const utms = getStoredUtmKeys();
  return {
    event_campaign: utms.utm_campaign,
    event_content: utms.utm_content,
    event_medium: utms.utm_medium,
    event_source: utms.utm_source,
    event_term: utms.utm_term,
  };
};

/**
 * Generates a string with UTM appended in it
 * useful for appending this to outgoing URLs
 * @example
 * ```
 *  const linkWithUtms = getUrlWithUtms('https://smallcase.com')
 *
 * <Link to={linkWithUtms}>
 *    Sc  with UTMs attached
 * </Link>
 *
 * ```
 * */
export const getUrlWithUtms = (baseURL: string) => {
  const url = new URL(baseURL);

  const utmParams = getStoredUtmKeys();

  Object.entries(utmParams).forEach(([key, value]) => {
    // We already get valid UTMs here via getStoredUtmKeys
    // But we drop NOT_AVAILABLE ones from going into the URL
    // Should these go in URL anyway?
    if (value === NOT_AVAILABLE) {
      return;
    }

    if (value) {
      url.searchParams.set(key, value);
    }
  });

  // we replace trailing slash here
  return url.toString().replace(/\/$/, '');
};
