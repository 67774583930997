import mixpanel from 'mixpanel-browser';
import TrackingLibraryBase from './BaseTrackingLibrary';
import { AnalyticsLibraries, type UserObjectType } from '../constants';
import { validateTrackingValueTypes } from '../analyticsUtils';
import { OnboardingStatuses } from '@/Onboarding/analytics/constants';

class MixPanel extends TrackingLibraryBase {
  label = AnalyticsLibraries.MIX_PANEL;

  load(): void {
    mixpanel.init(process.env.MIX_PANEL_TOKEN!, {
      debug: !!process.env.MIX_PANEL_DEBUG,
      loaded: this.setLoaded,
      persistence: 'localStorage',
      track_pageview: true,
    });
  }

  /**
   * Identify the user and set the data that is required in every track event
   */
  setUser(userData: UserObjectType): void {
    /** No alias will be created on .com/app in case of guest user, and this id will be samId instead of mfUserId(analytics.id) */
    mixpanel.identify(userData.analytics.id);

    const peopleData = validateTrackingValueTypes({
      // when mf user does not exist (onboarding not started), this id will be SAM_ID
      // else it will be mfUserId
      analyticsId: userData.analytics.id,
      // conditionally adding onboarding status here, because if user is not onboard onboarding will take care of status update.
      // and since we are spreading the object is flattening and properties are added in the main object and empty object will be removed
      ...(!userData.onboarding.required
        ? { onboardingStatus: OnboardingStatuses.completed }
        : {}),
    });

    mixpanel.people.set(peopleData);
  }

  updateUser(data: Record<string, string | number | boolean>): void {
    mixpanel.people.set(data);
  }

  track(eventLabel: string, data: object): void {
    // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
    mixpanel.track(eventLabel, data);
  }

  /** clears super properties and generates a new random distinct_id */
  reset() {
    mixpanel.reset();
  }

  // REVIEW
  isAvailable(): boolean {
    // the mixpanel library takes care of batching events
    // so the track method can be called even before the mixpanel script is loaded
    return true;
  }
}

export default MixPanel;
