class HttpRequestError extends Error {
  data: unknown;
  jwt?: string | null;

  constructor(
    message: string,
    data: unknown,
    stack?: string,
    jwt?: string | null,
  ) {
    super(message);
    Object.setPrototypeOf(this, HttpRequestError.prototype);
    this.name = `${Error.name} ${message}`;
    this.data = data;
    this.stack = stack;
    this.message = message;
    this.jwt = jwt ? jwt : null;
  }
}

export default HttpRequestError;
