import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { useEffect, useState } from 'react';

// * --- Query Specific logic ---
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Disabling query retries as retry will be done by ky.
      // This is for 1 reason
      // If you try to chain multiple promises and one fails query will retry all of them, which we don't want.
      // Ky is more closed and linked individually to each promise hence retry ability is on ky.
      retry: false,
    },
    mutations: {
      // Disabling query retries as retry will be done by ky.
      // This is for 1 reason
      // If you try to chain multiple promises and one fails query will retry all of them, which we don't want.
      // Ky is more closed and linked individually to each promise hence retry ability is on ky.
      retry: false,
    },
  },
});

/**
 * * This to enable the react dev tools in production.
 * * Lazy loading it as don't want it to be part of main bundle.
 * * and by default this will be off and can be toggled with window.toggleDevtools fn.
 */
const ReactQueryDevToolsProduction = React.lazy(() =>
  // @ts-expect-error - Disabling this as this is a type issue with dev tools
  import('@tanstack/react-query-devtools/production').then((d) => ({
    default: d.ReactQueryDevtools,
  })),
);

// * --- Query Specific logic ends ---

type Props = {
  /**
   * consumer app for which we need to provide the query
   */
  children: React.ReactNode;
};

/**
 * Provider component to wrap the react query.
 * 1. Contains react query setup logic isolated.
 * 2. Prevent un-necessary re-renders of the children when the dev tools are toggled
 * @param props - props required by the provider
 * @returns App wrapped with react query provider
 */
export default function QueryProvider(props: Props) {
  const [showDevtools, setShowDevtools] = useState<boolean>(false);

  useEffect(() => {
    window.toggleDevtools = () =>
      setShowDevtools((currentValue) => !currentValue);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
      {showDevtools ? <ReactQueryDevtools /> : null}
      {showDevtools ? (
        <React.Suspense>
          <ReactQueryDevToolsProduction />
        </React.Suspense>
      ) : null}
    </QueryClientProvider>
  );
}
