import { Button, DecorativeImage, Heading } from '@smallcase/components';
import { Component, ErrorInfo, ReactNode } from 'react';
import ErrorSvg from '@/assets/api-error.svg';
import { exitWithInternalError } from '@/gatewayApp/utils/utils';

// track
import { trackMfError } from '@/analytics/analyticsUtils';
import { FlowName, MfErrorType } from '@/analytics/sharedEventsAndProperties';

import { captureException } from '@sentry/react';
import './ErrorBoundary.css';

type Props = {
  /**
   * Component whose errors needs to be caught
   */
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
};

/**
 * Error Boundary to be wrapped around components for catching un-wanted errors
 */
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.group('Error Boundary');
    console.table({ error, errorInfo });
    // Probably not a good place to do it but for now, we are logging the error to sentry
    // This will help us see if all things work well on sentry on staging and production builds
    captureException(error);
    console.groupEnd();
    trackMfError({
      errorType: MfErrorType.feError,
      error: JSON.stringify(error),
      errorInfo: JSON.stringify(errorInfo),
      flowName: FlowName.UNKNOWN,
    });
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <div styleName="wrapper">
          <DecorativeImage src={ErrorSvg} styleName="image" />
          <Heading as="h2">Oops, we are facing some technical issue</Heading>
          <p className="text-light mb24 mt8 text-14 lh-144 ">
            Due to some technical issues we are not able to process the request.
            Meanwhile, you can refresh this page or contact{' '}
            <a href="mailto:support@smallcase.com">support@smallcase.com</a>
          </p>
          <Button
            onClick={exitWithInternalError}
            className="button btn btn-md btn-primary-blue"
          >
            Got it
          </Button>
        </div>
      );
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
