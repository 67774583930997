import TrackingLibraryBase from './BaseTrackingLibrary';
import { validateTrackingValueTypes } from '../analyticsUtils';
import {
  AnalyticsEventNames,
  AnalyticsLibraries,
  type UserObjectType,
} from '../constants';
import { loadCleverTapTracking } from './trackingInitScripts';
import { CleverTapEventWhitelist } from '../eventWhitelist';

const cleverTapEventWhitelist = Object.values(CleverTapEventWhitelist);

/**
 * analytics interface for https://clevertap.com
 */
class CleverTapAnalytics extends TrackingLibraryBase {
  label = AnalyticsLibraries.CLEVER_TAP;

  load(): void {
    loadCleverTapTracking(() => this.setLoaded());
  }

  private getCtObject() {
    // @ts-ignore - not adding this type to global to prevent usage elsewhere
    return window.clevertap;
  }

  track(eventLabel: AnalyticsEventNames, data: object): void {
    if (cleverTapEventWhitelist.includes(eventLabel)) {
      const ct = this.getCtObject();
      ct.event.push(eventLabel, data);
    }
  }

  setUser(userData: UserObjectType): void {
    const ct = this.getCtObject();
    const customUserProperties = {
      // common properties - available for all user types - SAM, BAM
      analyticsId: userData.analytics.id,
      cleverTapIdentityId: userData.analytics.clevertap?.id ?? '',
    };

    const filteredUserProperties =
      validateTrackingValueTypes(customUserProperties);

    ct.onUserLogin.push({
      Site: {
        /**
         * cleverTapIdentityId is samId on app/.com and bamId (broker mongo id) on BP/DM
         * For Bp, its bamId before the user performs merge on .com/app
         * after merge this changes to samId on BP too
         * once merge happens the bp profile is marked migratedToSam and will not be used
         * This is done as there is no merge functionality on clevertap so we are marking
         * profiles as duplicate on merge manually
         */
        Identity: userData.analytics.clevertap?.id ?? '',

        // custom properties
        ...filteredUserProperties,
      },
    });
  }

  updateUser(data: Record<string, string | number | boolean>): void {
    const ct = this.getCtObject();
    ct.profile.push({
      Site: { ...data },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  isAvailable(): boolean {
    return Boolean(this.getCtObject());
  }

  reset(): void {
    // no-op
    // no reset function exists
  }
}

export default CleverTapAnalytics;
