import { ObjectValues } from '@smallcase/types';

/** `type` of message send to gw-sdk in postMessage, this key: `type` is not respected by nativeSdk. */
export const sdkMessageType = {
  /** State where user can't move fwd. will need meta data, errors, success. */
  TRANSACTION_COMPLETE: 'TRANSACTION_COMPLETE',
  /** When user cancelled the transaction */
  USER_CANCELLED: 'USER_CANCELLED',
  /** When transaction time is exceeded and current transaction expires. */
  TRANSACTION_EXPIRED: 'TRANSACTION_EXPIRED',
  /** When user flow is complete but transaction is still not complete and waiting for some async action like payment */
  PROCESSING: 'PROCESSING',
} as const;

export type SdkMessageTypeValues = ObjectValues<typeof sdkMessageType>;

/** Part of meta data of the transaction */
export const sdkStatusType = {
  /** When user can not proceed fwd/ Terminal stage. The trx is marked as completed. */
  COMPLETED: 'COMPLETED',
  /** When user encounters some error. */
  ERRORED: 'ERRORED',
  /** when the transaction is still waiting for some async action to complete. like payment confirmation */
  PROCESSING: 'PROCESSING',
} as const;

export type SdkStatusTypeValues = ObjectValues<typeof sdkStatusType>;

export const OnboardingInteractionErrors = {
  CAN_ERROR: 'CAN_ERROR',
  BANK_VERIFICATION_ERROR: 'BANK_VERIFICATION_ERROR',
} as const;

export const MfTxnInteractionErrors = {
  USER_CANCELLED_TXN: 'USER_CANCELLED_TXN',
  DUPLICATE_REQUEST: 'DUPLICATE_REQUEST',
  CREDIT_FAILED: 'CREDIT_FAILED',
  DEBIT_FAILED: 'DEBIT_FAILED',
  ADDRESS_RESOLUTION_FAILED: 'ADDRESS_RESOLUTION_FAILED',
  TRANSACTION_FAIL: 'TRANSACTION_FAIL',
  PAYMENT_NOT_AUTHORIZED: 'PAYMENT_NOT_AUTHORIZED',
  COLLECT_EXPIRED: 'COLLECT_EXPIRED',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
} as const;

export const MfInteractionErrors = {
  // gw txn generic
  TRANSACTION_SESSION_EXPIRED: 'TRANSACTION_SESSION_EXPIRED',
  USER_CANCELLED: 'USER_CANCELLED',
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  INVALID_ORDER: 'INVALID_ORDER',
  TRADING_NOT_ENABLED: 'TRADING_NOT_ENABLED',
  TRADING_NOT_ALLOWED: 'TRADING_NOT_ALLOWED',
  TRANSACTION_EXPIRED: 'TRANSACTION_EXPIRED',
  // onboarding
  ...OnboardingInteractionErrors,
  // mf txn
  ...MfTxnInteractionErrors,
} as const;
export type MfInteractionErrors = ObjectValues<typeof MfInteractionErrors>;

export type OnboardingInteractionErrors = ObjectValues<
  typeof OnboardingInteractionErrors
>;

export type MfTxnInteractionErrors = ObjectValues<
  typeof MfTxnInteractionErrors
>;

/** Errors with code that act as meta data while sending postMessage to gw-sdk */
export const interactionErrorMap = {
  /**
   * When transaction time is exceeded and current transaction expires.
   * NOTE: not being used in mf platform as of now (when trx_id is valid, but the fetchUserDetails() failed)
   * This will be checked on backend, they will throw a particular error code and we will postMessage 👇🏼 accordingly by marking it complete.
   * This code handling will reside in our httpHandler
   */
  TRANSACTION_SESSION_EXPIRED: {
    code: 1005,
    error: 'transaction_session_expired',
  },
  /** When user does something that leads to cancel the transaction. */
  USER_CANCELLED: {
    code: 1012,
    error: 'user_cancelled',
  },
  /** Some error caused by bad code breakage. */
  INTERNAL_ERROR: {
    code: 2000,
    error: 'internal_error',
  },
  /**
   * API gives error because of some bad payload, eg. orderId not present, etc.
   * NOTE: not being used in mf platform as of now
   */
  INVALID_ORDER: {
    code: 3002,
    error: 'invalid_order',
  },
  /**
   * When onboarding is not fully done/for some reason user can not to transactions.
   * User is anyway redirected to onboarding flow if he/she is not eligible.
   * NOTE: not being used in mf platform as of now
   */
  TRADING_NOT_ENABLED: {
    code: 1030,
    error: 'trading_not_enabled',
  },
  /** When onboarding is done but user cannot trade in this particular scheme for some reason (dmat, regular) user can not to transactions. */
  TRADING_NOT_ALLOWED: {
    code: 1031,
    error: 'trading_not_allowed',
  },
  /** when the user is trying to use the old transaction id which is expired */
  TRANSACTION_EXPIRED: {
    code: 3001,
    error: 'transaction_expired',
  },

  // 5001 - 5010 has user CAN/profile issues
  CAN_ERROR: {
    code: 5001,
    error: 'can_error',
  },
  BANK_VERIFICATION_ERROR: {
    code: 5002,
    error: 'bank_verification_error',
  },

  /** Mf Transaction error maps */
  CREDIT_FAILED: {
    code: 5015,
    error: 'credit_failed',
  },
  DEBIT_FAILED: {
    code: 5016,
    error: 'debit_failed',
  },
  PAYMENT_FAILED: {
    code: 5018,
    error: 'payment_failed',
  },
  TRANSACTION_FAIL: {
    code: 5400,
    error: 'transaction_fail',
  },
  PAYMENT_NOT_AUTHORIZED: {
    code: 5403,
    error: 'payment_not_authorized',
  },
  ADDRESS_RESOLUTION_FAILED: {
    code: 5404,
    error: 'address_resolution_failed',
  },
  DUPLICATE_REQUEST: {
    code: 5409,
    error: 'duplicate_request',
  },
  /**
   * Billdesk Payment window expired (user paid later/ Not paid at all)
   * NOTE: not being used in mf platform as of now
   */
  COLLECT_EXPIRED: {
    code: 5410,
    error: 'collect_expired',
  },
  USER_CANCELLED_TXN: {
    code: 5444,
    error: 'user_cancelled_txn',
  },
} satisfies Record<MfInteractionErrors, { code: number; error: string }>;

export type InteractionErrorMap = ObjectValues<typeof interactionErrorMap>;

export const intents = {
  ONBOARDING: 'ONBOARDING',
  TRANSACTION: 'TRANSACTION',
  MANDATE: 'MANDATE',
  SIP_SETUP: 'SIP_SETUP',
} as const;

export type Intents = ObjectValues<typeof intents>;

export const transactionOrderTypes = {
  BUY: 'BUY',
  SELL: 'SELL',
} as const;

export type TransactionOrderTypes = ObjectValues<typeof transactionOrderTypes>;

export const transactionOrderConfigTypes = {
  NEW: 'NEW',
  SECURITIES: 'SECURITIES',
} as const;

export type TransactionOrderConfigTypes = ObjectValues<
  typeof transactionOrderConfigTypes
>;
