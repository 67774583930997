import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { NOT_AVAILABLE } from '@/analytics/constants';
import { TrackOrderTypes } from '@/SingleMutualFunds/analytics/constants';

export interface TxnTrackProps {
  /** Type of the txn, ONETIME, SIP, BUY, EXIT */
  orderType: TrackOrderTypes | typeof NOT_AVAILABLE;
  /** If the mandate exists or not */
  mandateExists: boolean | typeof NOT_AVAILABLE;
  /** If the SIP is set or not */
  isSIPSet: boolean | typeof NOT_AVAILABLE;
  /** Amount entered by the user for txn */
  amountEntered: number | typeof NOT_AVAILABLE;
  /** If the user is selling all the units */
  sellAllUnits: boolean | typeof NOT_AVAILABLE;
}

export interface OrderflowTrackingStore extends TxnTrackProps {
  /**
   * function to set the tracking state
   * @param params - params required by the function
   */
  setTrackingDetails(params: Partial<TxnTrackProps>): void;
  /**
   * function to clear out the store
   */
  clearTrackingStore(): void;
}

export const TXN_TRACKING_STORE_INITIAL_STATE: TxnTrackProps = {
  orderType: NOT_AVAILABLE,
  mandateExists: NOT_AVAILABLE,
  isSIPSet: NOT_AVAILABLE,
  amountEntered: NOT_AVAILABLE,
  sellAllUnits: NOT_AVAILABLE,
} as const;

/**
 * zustand store to manage the transactions on the platform
 */
export const useTxnTrackingInfo = create<OrderflowTrackingStore>()(
  devtools(
    (set) => ({
      ...TXN_TRACKING_STORE_INITIAL_STATE,

      setTrackingDetails(params) {
        set({ ...params });
      },
      clearTrackingStore() {
        set(() => TXN_TRACKING_STORE_INITIAL_STATE);
      },
    }),
    {
      name: 'TRACK_TRANSACTION_STORE',
      anonymousActionType: 'TRACK_TRANSACTION_STORE',
    },
  ),
);
