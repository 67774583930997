import { apiMap } from '@/constants/apiMap';
import { httpRequest } from '@/httpService';
import {
  TransactionDetailsResponse,
  transactionDetailsResponseSchema,
} from './responseSchema';
import { ErrorLogging } from '@/errorReporting/sentry/logger';

/**
 * Service to fetch the transaction details of the MF based on JWT.
 * @returns - transaction details
 */
export async function getTransactionDetails(): Promise<TransactionDetailsResponse> {
  try {
    const response = await httpRequest({
      endpoint: apiMap.TRANSACTION_DETAILS,
      service: 'transaction',
      method: 'get',
    });

    const result = transactionDetailsResponseSchema.parse(response);

    return result;
  } catch (err) {
    ErrorLogging.captureException(err, 'SINGLE_MUTUAL_FUND');
    throw err;
  }
}
