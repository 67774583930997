import { useEffect } from 'react';

/**
 * Useful for Modals, to lock the scroll on body when modal is opened
 * Note that the class to lock scroll is specific to the codebase
 */
function useBodyScrollLock() {
  useEffect(() => {
    document.body.classList.add('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);
}

export { useBodyScrollLock };
