import { useEffect } from 'react';

/**
 *
 * @param element
 * @returns an object with the first and the last focusable element inside element
 */
function getFocusableElements(element: HTMLElement) {
  const focusableEls = element.querySelectorAll(
    'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), input[type="number"]:not([disabled]), select:not([disabled])',
  );

  // find the first and last focusable elements, so that we can cycle between them
  const firstFocusableEl = focusableEls[0];
  const lastFocusableEl = focusableEls[focusableEls.length - 1];

  // TODO: is there a better way for ts to figure out that these are focusable elements and allow focus method invocation on these?
  return {
    firstFocusableEl: firstFocusableEl as HTMLElement,
    lastFocusableEl: lastFocusableEl as HTMLElement,
  };
}

// TODO: move to sc-fe-components
/**
 * This function is taken as is from https://hidde.blog/using-javascript-to-trap-focus-in-an-element/
 * It takes a HTMLElement as input and traps focus inside it
 * by finding all the interactive elements inside the element, and cycling focus within them
 * It lets browser handle the focus in general, but when the focus is on the first or last interactive element,
 * it takes control and cycles the focus
 *
 *
 * This is useful for Modals and other elements, where we need to trap focus for accessibility purposes.
 *
 * Please make sure that you have a mechanism to untrap the focus on the consumer side as and when required
 * (For example, by unmounting the component that traps focus)
 */
function useFocusTrap(element: HTMLElement | null) {
  useEffect(() => {
    if (!element) return;

    const { firstFocusableEl, lastFocusableEl } = getFocusableElements(element);
    const KEYCODE_TAB = 9;

    const handleKeydown = (e: KeyboardEvent) => {
      const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB;

      // ignore all other keypresses, we only care about tab
      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        /* shift + tab */ if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } /* tab */ else if (document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus();
        e.preventDefault();
      }
    };

    element.addEventListener('keydown', handleKeydown);

    return () => {
      element.removeEventListener('keydown', handleKeydown);
    };
  }, [element]);
}

export { useFocusTrap };
