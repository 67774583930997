/*
  !NOTE: All endpoints are written in dictionary manner (alphabetical order)
  If you find any discrepancy, Please resolve them
*/
export const apiMap = {
  BANK_BRANCH_SEARCH: 'v1/journey/search-branch',
  BANK_SEARCH: 'v1/journey/list-banks',
  CREATE_CAN: 'v1/journey/create-can',
  CREATE_CAN_V2: 'v2/journey/create-can',
  CREATE_MANDATE: 'mf/user/mandates',
  CREATE_ORDER: 'mf/user/external/:mfId/order',
  EXECUTE_MANDATE: 'mf/user/mandates/:mandateId',
  EXECUTE_ORDER: 'mf/user/orders/:orderId',
  GET_JOURNEY: 'v1/journey',
  GET_MANDATE_STATUS: 'mf/user/mandates/:mandateId',
  GET_ONBOARDING_KYC_STATUS: 'v1/journey/kyc-status',
  LOGIN: 'mf/auth/login/gateway',
  MF_INVESTMENT: 'mf/user/external/:mfId',
  MF_MARKET_DATA: 'mf/market/info',
  MF_USER: 'mf/auth/user',
  ORDER_STATUS: 'mf/user/orders/:orderId',
  RESET_ONBOARDING_JOURNEY: 'v1/journey/reset',
  RETRY_ORDER: 'mf/user/orders/:orderId/retry',
  SET_NOMINEE_DETAILS: 'v1/journey/nominee-details',
  SET_ONBOARDING_AKYC_DATA: 'v1/journey/additional-kyc',
  SET_ONBOARDING_BANK_DETAILS: 'v1/journey/verify-bank',
  SET_ONBOARDING_KYC_DATA: 'v1/journey/kyc-details',
  TRANSACTION_DETAILS: 'mf/gateway/transaction',
  TRIGGER_ONBOARDING_KYC_OTP: 'v1/journey/otp/kyc',
  TRIGGER_ONBOARDING_NOMINEE_SET_OTP: 'v1/journey/otp/nominee',
  TRIGGER_TRANSACTIONS_OTP: 'mf/user/orders/:orderId/otp/send',
  USER_PROFILE: 'v1/journey/user-profile',
  USER_SIGNATURE: 'v1/journey/user-signature',
  VALIDATE_LOGIN: 'mf/auth/me',
  VERIFY_ONBOARDING_KYC_SET_OTP: 'v1/journey/otp/kyc/verify',
  VERIFY_ONBOARDING_NOMINEE_SET_OTP: 'v1/journey/otp/nominee/verify',
  VERIFY_TRANSACTIONS_OTP: 'mf/user/orders/:orderId/otp/verify',
} as const;
