/* eslint-disable */
// this is moved to a separate file because ts and eslint can't comprehend this monstrosity

// /**
//  * script that loads the google analytics tracking library.
//  *
//  * @param {()=>void} onLoad - callback
//  */
// export const loadGTMTracking = (onLoad) => {
//   const scriptId = 'gtm_script';
//   if (document.getElementById(scriptId)) {
//     return;
//   }

//   (function (w, d, s, l, i) {
//     w[l] = w[l] || [];
//     w[l].push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' });
//     window._gtag = function () {
//       window.dataLayer.push(arguments[0]);
//     };
//     var f = d.getElementsByTagName(s)[0],
//       j = d.createElement(s),
//       dl = l != 'dataLayer' ? '&l=' + l : '';
//     j.async = true;
//     j.id = scriptId;
//     j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
//     j.onload = onLoad;
//     f.parentNode.insertBefore(j, f);
//   })(window, document, 'script', 'dataLayer', GTM_ID);
// };

/**
 * script that loads the clevertap tracking library.
 *
 * @param {()=>void} onLoad - callback
 */
export const loadCleverTapTracking = (onLoad) => {
  const clevertap = {
    event: [],
    profile: [],
    account: [],
    onUserLogin: [],
    notifications: [],
    privacy: [],
  };

  clevertap.account.push({ id: process.env.CLEVERTAP_ID });
  clevertap.privacy.push({ optOut: false });
  clevertap.privacy.push({ useIP: false });

  window.clevertap = clevertap;

  (() => {
    const wzrk = document.createElement('script');
    wzrk.type = 'text/javascript';
    wzrk.async = true;
    wzrk.onload = onLoad;
    wzrk.src =
      ('https:' === document.location.protocol
        ? 'https://d2r1yp2w7bby2u.cloudfront.net'
        : 'http://static.clevertap.com') + '/js/a.js';

    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wzrk, s);
  })();
};
