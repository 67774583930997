import { apiMap } from '@/constants/apiMap';
import {
  OrderLabel,
  PaymentModes,
  batchStatus,
  orderLabel,
  orderStatus,
  paymentModes,
} from '@/constants/orderConstants';
import { ErrorLogging } from '@/errorReporting/sentry/logger';
import { httpRequest } from '@/httpService';
import { z } from 'zod';

export type CreateOrderParams = {
  /**
   * amount entered by user
   */
  amount: number | undefined;
  /**
   * label of the order
   */
  label: OrderLabel;
  /**
   * mutual fund id on which order needs to be placed
   */
  mfId: string;
};

const createOrderResponseSchema = z
  .object({
    data: z.object({
      orderID: z.string(),
    }),
  })
  .transform((response) => ({
    data: { orderId: response.data.orderID },
  }));

export type CreateOrderResponse = z.infer<typeof createOrderResponseSchema>;

/**
 * service to create an order and get an order id in return to execute the order further.
 * @param params - params required for creating an order id
 * @returns response of the api call
 */
export async function createOrder(
  params: CreateOrderParams,
): Promise<CreateOrderResponse['data']> {
  try {
    const response = await httpRequest({
      method: 'post',
      endpoint: apiMap.CREATE_ORDER,
      service: 'transaction',
      json: {
        type: params.label,
        amount: params.amount,
      },
      pathParams: {
        ':mfId': params.mfId,
      },
    });
    const result = createOrderResponseSchema.parse(response);
    ErrorLogging.setTag('orderId', result.data.orderId);
    return result.data;
  } catch (err) {
    ErrorLogging.captureException(err, 'SINGLE_MUTUAL_FUND');
    throw err;
  }
}

export type TriggerOtpParams = {
  /**
   * order id of the order
   */
  orderId: string;
};

/**
 * function to trigger otp on user's phone number for validating transaction
 * @param params - params required to trigger the otp
 * @returns - void
 */
export async function triggerOtp(params: TriggerOtpParams): Promise<void> {
  try {
    await httpRequest({
      endpoint: apiMap.TRIGGER_TRANSACTIONS_OTP,
      method: 'post',
      service: 'transaction',
      pathParams: {
        ':orderId': params.orderId,
      },
    });
    return;
  } catch (err) {
    ErrorLogging.captureException(err, 'SINGLE_MUTUAL_FUND');
    throw err;
  }
}

export type VerifyOtpParams = {
  /**
   * order id of the single mf order
   */
  orderId: string;
  /**
   * otp entered by the user
   */
  otp: string;
};

/**
 * function to verify the otp entered by the user
 * @param params - params required to verify the otp
 * @returns - void
 */
export async function verifyOtp(params: VerifyOtpParams): Promise<void> {
  try {
    await httpRequest({
      endpoint: apiMap.VERIFY_TRANSACTIONS_OTP,
      method: 'post',
      service: 'transaction',
      pathParams: {
        ':orderId': params.orderId,
      },
      json: {
        otp: params.otp,
      },
    });
    return;
  } catch (err) {
    ErrorLogging.captureException(err, 'SINGLE_MUTUAL_FUND');
    throw err;
  }
}

export type ExecuteOrderParams = {
  /**
   * order id for which the order needs to be executed
   */
  orderId: string;
  /**
   * the payment type selected by the user
   */
  paymentType?: PaymentModes;
};

const executeOrderResponseSchema = z.object({
  data: z.object({
    redirectUrl: z.string().optional(),
  }),
});

export type ExecuteOrderResponse = z.infer<typeof executeOrderResponseSchema>;

/**
 * function to execute the order for the given mutual fund
 * @param params - params required to execute the order
 * @returns - api response of order execution
 */
export async function executeOrder(
  params: ExecuteOrderParams,
): Promise<ExecuteOrderResponse['data']> {
  try {
    const response = await httpRequest({
      endpoint: apiMap.EXECUTE_ORDER,
      method: 'post',
      service: 'transaction',
      pathParams: {
        ':orderId': params.orderId,
      },
      json: {
        paymentType: params.paymentType,
      },
    });
    const result = executeOrderResponseSchema.parse(response);
    return result.data;
  } catch (err) {
    ErrorLogging.captureException(err, 'SINGLE_MUTUAL_FUND');
    throw err;
  }
}

export type GetOrderStatusParams = {
  /**
   * order id for which the order status needs to be fetched
   */
  orderId: string;
};

const orderStatusResponseSchema = z.object({
  data: z.object({
    _id: z.string(),
    otpStatus: z.string(), // TODO enum
    otpDigits: z.number(),
    status: z.nativeEnum(batchStatus),
    // 👇🏼 Being used for showing copy OTP screen and autoSubmitting OTP in case of non-mandate
    paymentType: z.nativeEnum(paymentModes).optional(),
    paymentUrl: z.string().optional(),
    paymentStatus: z.string().optional(),
    mfuTag: z.string().optional(),
    orders: z.array(
      z
        .union([
          z.object({
            orderType: z.enum([orderLabel.BUY]),
            amount: z.number(),
          }),
          z.object({
            orderType: z.enum([orderLabel.SELL]),
            units: z.number(),
          }),
        ])
        .and(
          z.object({
            tag: z.string(),
            orderType: z.nativeEnum(orderLabel),
            status: z.nativeEnum(orderStatus),
            mfId: z.string(),
            folio: z.string(),
            message: z.string().optional(),
            errorCode: z.string().optional(),
          }),
        ),
    ),
  }),
});

export type OrderStatusResponse = z.infer<typeof orderStatusResponseSchema>;

/**
 * function to fetch the order status
 * @param params - params required to fetch the order status
 * @returns - order status details
 */
export async function fetchOrderStatus(
  params: GetOrderStatusParams,
): Promise<OrderStatusResponse['data']> {
  try {
    const response = await httpRequest({
      endpoint: apiMap.ORDER_STATUS,
      method: 'get',
      service: 'transaction',
      pathParams: {
        ':orderId': params.orderId,
      },
    });

    const result = orderStatusResponseSchema.parse(response);

    return result.data;
  } catch (err) {
    ErrorLogging.captureException(err, 'SINGLE_MUTUAL_FUND');
    throw err;
  }
}

const retryOrderResponseSchema = createOrderResponseSchema;
type RetryOrderResponse = z.infer<typeof retryOrderResponseSchema>;
type RetryOrderParams = {
  /**
   * order id of the order that has to be retried
   */
  orderId: string;
};

/**
 * function to fetch the order status
 * @param params - params required to fetch the order status
 * @returns - order status details
 */
export async function retryOrder(
  params: RetryOrderParams,
): Promise<RetryOrderResponse['data']> {
  try {
    const response = await httpRequest({
      method: 'post',
      endpoint: apiMap.RETRY_ORDER,
      service: 'transaction',
      pathParams: {
        ':orderId': params.orderId,
      },
    });
    const result = retryOrderResponseSchema.parse(response);
    return result.data;
  } catch (err) {
    ErrorLogging.captureException(err, 'SINGLE_MUTUAL_FUND');
    throw err;
  }
}
