import { MfInvestmentResponse } from '@/services/investments/investmentServices';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { MakePropertiesRequired } from '@smallcase/types';

type State = {
  meta: MfInvestmentResponse['data']['meta'];
  /**
   * mutual fund scheme related data
   */
  scheme: MfInvestmentResponse['data']['scheme'];
  /**
   * transactional info for the given mutual fund
   */
  transactionalInfo?: MfInvestmentResponse['data']['transactionalInfo'];
  /**
   * units user holds of this mutual fund
   */
  units: MfInvestmentResponse['data']['units'];
  /**
   * return details of the mutual fund
   */
  returns?: MfInvestmentResponse['data']['returns'];
  /**
   * list of folios held by user in a given mutual fund investment
   */
  folios: MfInvestmentResponse['data']['folios'];
  /**
   * function to setup the mutual fund data in the store
   * @param params - params required for setting up the data
   */
  setInfo(
    params: Omit<MakePropertiesRequired<State, 'transactionalInfo'>, 'setInfo'>,
  ): void;
};

/**
 * zustand store for storing the mutual fund data
 */
export const useMutualFundTransactionalInfo = create<State>()(
  devtools(
    (set) => ({
      meta: {
        amc: {
          code: '',
        },
        fundType: '',
      },
      scheme: {
        code: '',
        name: '',
        subType: '',
        type: '',
      },
      transactionalInfo: undefined,
      units: 0,
      returns: undefined,
      folios: [],
      setInfo(params) {
        set({ ...params });
      },
    }),
    {
      name: 'MUTUAL_FUND_TRANSACTION_INFO',
      anonymousActionType: 'MUTUAL_FUND_TRANSACTION_INFO',
    },
  ),
);
