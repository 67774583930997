import getUrlParams from '@/gatewayApp/utils/urlParams';
import { getResponsiveWidthFlags } from '@smallcase/context/MobileContext';
import {
  AnalyticsExtraProps,
  AssetType,
  CommonTrackingPropertiesType,
  GatewayType,
  NOT_AVAILABLE,
  PossibleAnalyticsPropertyType,
} from './constants';
import { getStoredUtmKeysAsEvent } from './utmHelper';
import analyticsManager from './analyticsManager';
import {
  FlowName,
  MfErrorType,
  SharedAllowedPropertiesType,
  SharedErrorEvents,
} from './sharedEventsAndProperties';

/**
 * validates and replaces all invalid value types with "N/A"
 * analytics libraries generally only supports string, numbers and booleans
 */
export const validateTrackingValueTypes = (
  data?: Record<string, unknown>,
): Record<string, string | number | boolean> => {
  const processedData: Record<string, string | number | boolean> = {};

  /**
   * removes all undefined and null values and replaces them with N/A
   */
  if (data && Object.keys(data).length > 0) {
    Object.keys(data).forEach((key) => {
      const val = data[key];
      if (
        (typeof val === 'string' && val.length > 0) ||
        typeof val === 'boolean' ||
        typeof val === 'number'
      ) {
        processedData[key] = val;
      } else {
        // everything else is removed
        processedData[key] = NOT_AVAILABLE;
      }
    });
  }
  return processedData;
};

/** Error event that would be fired for all the errors on the platform */
export const trackMfError = (
  errorObject: Partial<SharedAllowedPropertiesType>,
) => {
  const {
    flowName = FlowName.UNKNOWN,
    errorType = MfErrorType.unknown,
    errorMessage,
    errorInfo,
    error,
    onboardingStatus,
    orderType,
  } = errorObject;
  try {
    const conditionalProps: Parameters<typeof trackMfError>[0] = {};
    if (onboardingStatus) {
      Object.assign(conditionalProps, { onboardingStatus });
    }
    if (orderType) {
      Object.assign(conditionalProps, { orderType });
    }

    analyticsManager.track(SharedErrorEvents.GENERIC_ERROR, {
      flowName,
      errorType,
      errorMessage,
      errorInfo,
      error,
      ...conditionalProps,
    });
  } catch (e) {
    console.log(e);
  }
};

/**
 * It creates an object from keys in an array by extracting corresponding values from a superObject.
 * @param superObject - The `superObject` main superset object from which the values will be read.
 * @param keyArray - The `keyArray` array of strings containing keys that correspond
 * to properties in the `superObject` parameter.
 * @returns The function `createObjFromKeyArray` returns a new object created from the keys in the
 * `keyArray` that exist in the `superObject`. If the `keyArray` is empty or `superObject` is falsy,
 * the function returns `null`.
 */
export function createObjFromKeyArray(
  superObject: Record<string, PossibleAnalyticsPropertyType>,
  keyArray: Array<string>,
): Record<string, PossibleAnalyticsPropertyType> | null {
  if (!(Array.isArray(keyArray) && keyArray.length > 0) || !superObject)
    return null;
  /**
   * Creates an object of properties to set in store from the keys passed in `savePropertiesToStore`
   */
  const finalObject = keyArray.reduce((acc, propKey) => {
    const propValue = superObject[propKey];
    if (Object.prototype.hasOwnProperty.call(superObject, propKey)) {
      acc = Object.assign(acc, {
        [propKey]: propValue,
      });
    }
    return acc;
  }, {});
  return finalObject;
}

/**
 * gets a set of items that all events need to have
 */
export const getCommonTrackingProps = (): CommonTrackingPropertiesType &
  AnalyticsExtraProps => {
  // NOTE get from GW or create new zustand store for this to get these common values at least. Eg. 👇🏼
  // const userData = selectUserData(store);
  const {
    gateway,
    clientType,
    accessedFrom = '',
    sectionTag = '',
    assetType = AssetType.mf,
  } = getUrlParams();

  let eventProps: Record<string, string> = {};

  try {
    eventProps = getStoredUtmKeysAsEvent(); // TODO
  } catch {
    eventProps = {};
  }

  return {
    ...eventProps,
    accessedFrom,
    sectionTag,
    deviceType: clientType,
    gatewayType: getResponsiveWidthFlags().tabletOrLower
      ? GatewayType.mweb
      : GatewayType.web,
    gatewayName: gateway,
    clientType,
    assetType,
    // NOTE - other user level properties will go here if any, egg 👇🏼
    // sessionReferrerPartner: analyticsSessionStore.referrerId || NOT_AVAILABLE,
  };
};
