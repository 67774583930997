import React from 'react';
import { DecorativeImage } from '@smallcase/components';
import './AmcImage.css';

type Props = {
  /**
   * image size. Defaults to 15x15px. Pass only the number
   */
  size?: number;

  /**
   * mutual fund AMC Code to fetch the image
   * @see {@link https://app.mfcentral.com/fixtures/amcList.json}
   */
  amcCode?: string;
};

const FALLBACK_IMAGE = `${process.env.TICKET_TAPE_ASSETS_URL}amc-logos/fallback.png`;

/**
 * Util function that returns image link of amcLogo provided an amcCode.
 * @param amcCode - code of the amc to fetch the image
 * */
const getAmcImageUrl = (amcCode: string): string => {
  return amcCode
    ? `${process.env.TICKET_TAPE_ASSETS_URL}amc-logos/${amcCode.trim()}.png`
    : FALLBACK_IMAGE;
};

/**
 * function to replace the image src to a fallback image when fetching for amc logo fails
 * @param e - event object passed by the react param
 */
const onImageLoadError = (e: React.SyntheticEvent<HTMLImageElement>) => {
  (e.target as HTMLImageElement).src = FALLBACK_IMAGE;
};

/**
 * Function component for rendering AMC image where ever required
 * @param props - props required by the component
 * @returns - AMC image
 */
export default function MfAmcImage(props: Props & typeof defaultProps) {
  return (
    <div
      styleName="amc-logo-container"
      style={{
        width: `${props.size}px`,
        height: `${props.size}px`,
        minWidth: `${props.size}px`,
      }}
    >
      <DecorativeImage
        src={getAmcImageUrl(props.amcCode)}
        className="block full-width"
        onError={onImageLoadError}
      />
    </div>
  );
}

const defaultProps = {
  size: 20,
  amcCode: '',
};

MfAmcImage.defaultProps = defaultProps;
