import { ErrorLogging } from '@/errorReporting/sentry/logger';
import { getStorageItem, setStorageItem } from '@smallcase/utils/browser';

export const services = {
  MFO: 'MFO',
  MFT: 'MFT',
} as const;

const storageKeys = {
  SESSION_TOKEN: 'SESSION_TOKEN',
  TRANSACTION: 'TRANSACTION',
} as const;

type SessionDataParams = {
  /**
   * session token of the particular service
   */
  token: string;
  /**
   * Id of the transaction
   */
  transaction: string;
};

/**
 * function to handle the errors happened while accessing browser storage
 * @param err - error occurred while accessing storage
 */
const onStorageErrorHandler = (err: unknown): void => {
  ErrorLogging.captureException(err, 'NONE');
};

/**
 * function to set the session data in session storage
 * @param params - params required to set the session data
 */
export const setSessionData = (params: SessionDataParams): void => {
  setStorageItem(
    storageKeys.SESSION_TOKEN,
    params.token,
    'session',
    onStorageErrorHandler,
  );

  setStorageItem(
    storageKeys.TRANSACTION,
    params.transaction,
    'session',
    onStorageErrorHandler,
  );

  window[storageKeys.SESSION_TOKEN] = params.token;
  window[storageKeys.TRANSACTION] = params.transaction;
};

/**
 * function to get the session related data from the session storage
 * @returns session data
 */
export const getSessionData = ():
  | Pick<SessionDataParams, 'token'>
  | undefined => {
  let token = getStorageItem(
    storageKeys.SESSION_TOKEN,
    'session',
    onStorageErrorHandler,
  );

  if (token) {
    return { token };
  }

  token = window[storageKeys.SESSION_TOKEN];

  if (token) {
    return { token };
  }

  return undefined;
};

/**
 * function to get the session headers
 * @returns session headers for mft
 */
export const getSessionHeaders = () => {
  const details = getSessionData();

  return { 'x-mf-jwt': details?.token };
};

/**
 * function to get the last used transaction id of the user
 * @returns transaction id of the last used transaction
 */
export const getLastUsedTransactionId = (): string | undefined => {
  let trxId = getStorageItem(
    storageKeys.TRANSACTION,
    'session',
    onStorageErrorHandler,
  );

  if (trxId) {
    return trxId;
  }

  trxId = window[storageKeys.TRANSACTION] || undefined;

  return trxId;
};
