import { apiMap } from '@/constants/apiMap';
import { buyRestrictionCodes } from '@/constants/orderConstants';
import { ErrorLogging } from '@/errorReporting/sentry/logger';
import { httpRequest } from '@/httpService';
import { SERVICES } from '@/httpService/httpHandler';
import { z } from 'zod';

type MFMarketDataParams = {
  /**
   * mutual fund id whose data needs to be fetched
   */
  mfId: string;
};

const marketDataSchema = z.object({
  data: z.record(
    z.string(),
    z.object({
      data: z.object({
        mfId: z.string(),
        meta: z.object({
          amc: z.string().optional(),
          amcCode: z.string(),
          fundType: z.string(),
          name: z.string(),
        }),
      }),
      transactionalInfo: z.object({
        buy: z.union([
          z.object({
            allowed: z.literal(true),
            minInvestmentAmount: z.number(),
            multipleAmount: z.number(),
          }),
          z.object({
            allowed: z.literal(false),
            restrictionCode: z.nativeEnum(buyRestrictionCodes),
          }),
        ]),
      }),
    }),
  ),
});

type MarketDataSchema = z.infer<typeof marketDataSchema>;

/**
 * service to fetch the market data of the mutual fund
 * @param params - params to fetch the mutual fund data
 * @returns Market data of the mutual fund
 */
export async function fetchMutualFundTransactionalData(
  params: MFMarketDataParams,
): Promise<MarketDataSchema['data'][string]> {
  try {
    const response = await httpRequest({
      endpoint: apiMap.MF_MARKET_DATA,
      searchParams: {
        mfIds: params.mfId,
        errors: true,
        txInfo: true,
      },
      service: SERVICES.transaction,
    });

    const result = marketDataSchema.parse(response);

    return result.data[params.mfId];
  } catch (err) {
    ErrorLogging.captureException(err, 'SINGLE_MUTUAL_FUND');
    throw err;
  }
}
