import { apiMap } from '@/constants/apiMap';
import {
  buyRestrictionCodes,
  sellRestrictionCodes,
} from '@/constants/orderConstants';
import { ErrorLogging } from '@/errorReporting/sentry/logger';
import { httpRequest } from '@/httpService';
import { z } from 'zod';

type FetchInvestmentParams = {
  /**
   * unique id of the mutual fund
   */
  mfId: string;
};

const mfInvestmentResponseSchema = z.object({
  data: z.object({
    mfId: z.string(),
    scheme: z.object({
      name: z.string(),
      code: z.string().optional(),
      type: z.string().optional(),
    }),
    units: z.number(),
    meta: z.object({
      amc: z.object({
        name: z.string().optional(),
        code: z.string(),
      }),
      fundType: z.string(),
    }),
    folios: z.array(z.object({ isDemat: z.boolean() })),
    returns: z.object({
      networth: z.number(),
      investment: z.number(),
    }),
    transactionalInfo: z.object({
      buy: z.union([
        z.object({
          allowed: z.literal(true),
          minInvestmentAmount: z.number(),
          multipleAmount: z.number(),
        }),
        z.object({
          allowed: z.literal(false),
          restrictionCode: z.nativeEnum(buyRestrictionCodes),
        }),
      ]),
      sell: z.union([
        z.object({
          allowed: z.literal(true),
          maxSellUnits: z.number(),
          maxSellAmount: z.number(),
          minSellAmount: z.number().optional(),
          multipleAmount: z.number().optional(),
        }),
        z.object({
          allowed: z.literal(false),
          restrictionCode: z.nativeEnum(sellRestrictionCodes),
        }),
      ]),
    }),
  }),
});

export type MfInvestmentResponse = z.infer<typeof mfInvestmentResponseSchema>;

/**
 * service to fetch the details of mf investment
 * @param params - params required to fetch the mf details
 * @returns - mf investment
 */
export async function fetchMfInvestment(
  params: FetchInvestmentParams,
): Promise<MfInvestmentResponse['data']> {
  try {
    const response = await httpRequest({
      endpoint: apiMap.MF_INVESTMENT,
      service: 'transaction',
      method: 'get',
      pathParams: {
        ':mfId': params.mfId,
      },
    });

    const result = mfInvestmentResponseSchema.parse(response);
    return result.data;
  } catch (err) {
    ErrorLogging.captureException(err, 'SINGLE_MUTUAL_FUND');
    throw err;
  }
}

export const investmentsErrorResponseSchema = z.object({
  success: z.literal(false),
  code: z.string(),
});
