// import { LeadgenAnalyticsEventNames } from './leadgenAnalyticsEventConstants';
import {
  getCommonTrackingProps,
  validateTrackingValueTypes,
} from './analyticsUtils';

import {
  type AnalyticsEventNames,
  type AnalyticsExtraProps,
  type UserObjectType,
  AnalyticsLibraries,
} from './constants';

import { MixPanelAnalytics } from './libraries';
import type TrackingLibraryBase from './libraries/BaseTrackingLibrary';
import CleverTapAnalytics from './libraries/CleverTapAnalytics';
// import GA4Analytics from './trackingLibs/GA4Analytics';

const loadAtLaunch = true; // TODO might remove this

/**
 * Manages instances of various analytics libraries and delegates the events to
 * required libraries
 */
class AnalyticsManager {
  private libraries: Map<AnalyticsLibraries, TrackingLibraryBase> = new Map();

  // private chatLibraries: Map<AnalyticsLibraries, ChatLibraryBase> = new Map();

  constructor() {
    if (loadAtLaunch) {
      const libs = [
        // add new library instances here
        // that need to be loaded at launch
        new MixPanelAnalytics(),
        new CleverTapAnalytics(),
      ];

      /** GET the names of Libs */
      libs.forEach((lib) => {
        this.libraries.set(lib.getLabel(), lib);
      });

      /** LOAD all libs */
      this.libraries.forEach((lib) => lib.load());
    }
  }

  /**
   * decides where to send the event (if any exceptions to be made)
   * Here we can accept the `eventName` in param and decide with library to use to track that particular event.
   */
  private static getEventDestinations(): AnalyticsLibraries[] {
    return [
      AnalyticsLibraries.MIX_PANEL,
      AnalyticsLibraries.GOOGLE_TAG_MANAGER,
      AnalyticsLibraries.CLEVER_TAP,
    ];
  }

  /**
   * send analytics data to configured analytics libraries
   */
  track(
    eventLabel:
      | AnalyticsEventNames
      // | LeadgenAnalyticsEventNames
      | `SW -${string}`,
    data?: AnalyticsExtraProps,
  ) {
    try {
      const processedData = {
        ...getCommonTrackingProps(),
        ...validateTrackingValueTypes(data),
      };

      const destinations = AnalyticsManager.getEventDestinations();
      destinations.forEach((libName) => {
        const lib = this.libraries.get(libName);

        if (!lib) {
          console.error(
            libName,
            'was not initialized but',
            eventLabel,
            'was pushed to it',
          );
          return;
        }

        try {
          lib.track(eventLabel, processedData);
        } catch (error) {
          console.error('tracking failed for', libName, error);
        }
      });

      console.log('dev-tracking', { eventLabel, data });
    } catch (error) {
      console.error('tracking failed', error);
      // TODO Sentry
      // captureException(error as Error, {
      //   errorInfo: {
      //     name: 'tracking',
      //     eventLabel,
      //   },
      // });
    }
  }

  /**
   * set user info to the analytics libraries
   */
  setUser(user: UserObjectType) {
    this.libraries.forEach((lib) => {
      if (lib.isLoaded()) {
        try {
          lib.setUser(user);
        } catch (error) {
          console.error(
            'could not set user properties for',
            lib.getLabel(),
            error,
          );

          // TODO Sentry
          // captureException(
          //   new Error(`could not set user properties for ${lib.getLabel()}`),
          // );
        }
      }
    });
  }

  /**
   * update user properties in all active libraries
   */
  updateUser(data: Record<string, string | number | boolean>) {
    this.libraries.forEach((lib) => {
      if (lib.isLoaded()) {
        try {
          lib.updateUser(data);
        } catch (error) {
          console.error(
            'could not set user properties for',
            lib.getLabel(),
            error,
          );

          // TODO Sentry
          // captureException(
          //   new Error(`could not set user properties for ${lib.getLabel()}`),
          // );
        }
      }
    });
  }

  /**
   * Checking if the specified library is loaded
   */
  isLoaded(label: AnalyticsLibraries) {
    return this.libraries.get(label)?.isLoaded();
  }

  /** used to reset any user related data, mostly on logout */
  reset() {
    this.libraries.forEach((lib) => lib.reset());
  }
}

const analyticsManager = new AnalyticsManager();

export default analyticsManager;
