import React from 'react';
import ReactDOM from 'react-dom/client';
import '@smallcase/shringar/index.css';
import './styles/index.css';
import './errorReporting/sentry';
import App from './App';

ReactDOM.createRoot(document.getElementById('root')!).render(
  // this is commented because web otp auto read was not working fine with strict mode on dev / stag
  // does not impact prod anyway
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);
