import { Heading } from '@smallcase/components';
import React from 'react';

const FourOFour = () => {
  return (
    <div className="grid-container">
      <div className="banner-colored">
        <div className="text-center full-width">
          <Heading as="h1">404</Heading>
          <Heading as="h2" className="mb8">
            You are looking for something that isn&apos;t here
          </Heading>
          <Heading as="h3">
            Try <a href="https://www.smallcase.com">smallcase</a>, maybe?
          </Heading>
        </div>
      </div>
    </div>
  );
};

export default FourOFour;
